import React, { useEffect, useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, getDocs, doc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  IconButton,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, Save, Delete } from "@mui/icons-material";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const ReferralSystem = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const [referrals, setReferrals] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newReferral, setNewReferral] = useState({
    name: "",
    referralTrigger: "referee_paid_order",
    referralAmount: "",
    refereeTrigger: "referral_paid_order",
    refereeAmount: "",
    active: false,
  });
  const [loading, setLoading] = useState(false);

  const globalOptions = [
    { name: "Referee Order Paid", action: "referee_order_paid" },
    { name: "Referee Sign Up", action: "referee_sign_up" },
  ];

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const fetchReferrals = async () => {
    setLoading(true);
    const referralsCollection = collection(db, `${collectionPath}Marketing/Referrals/DataBase`);
    const snapshot = await getDocs(referralsCollection);
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setReferrals(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchReferrals();
  }, [db, collectionPath]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedReferrals = [...referrals];
    updatedReferrals[index][name] = parseFloat(value) || value;
    setReferrals(updatedReferrals);
  };

  const handleToggleActive = (index) => {
    const updatedReferrals = [...referrals];
    updatedReferrals[index].active = !updatedReferrals[index].active;
    setReferrals(updatedReferrals);
  };

  const startAddingNew = () => {
    setIsAddingNew(true);
    setNewReferral({
      name: "",
      referralTrigger: "referee_paid_order",
      referralAmount: "",
      refereeTrigger: "referral_paid_order",
      refereeAmount: "",
      active: false,
    });
  };

  const handleSaveNewReferral = async () => {
    if (!newReferral.name || !newReferral.referralAmount || !newReferral.refereeAmount) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const referralsCollection = collection(db, `${collectionPath}Marketing/Referrals/DataBase`);
      await addDoc(referralsCollection, newReferral);
      toast.success("Referral added!");
      setIsAddingNew(false);
      setNewReferral({
        name: "",
        referralTrigger: "referee_paid_order",
        referralAmount: "",
        refereeTrigger: "referral_paid_order",
        refereeAmount: "",
        active: false,
      });
      fetchReferrals();
    } catch (error) {
      toast.error("Failed to add referral.");
      console.error("Add Referral Error:", error);
    }
  };

  const handleSave = async (referral) => {
    if (!referral.name || !referral.referralAmount || !referral.refereeAmount) {
      toast.error("All fields are required.");
      return;
    }

    const referralDocRef = doc(db, `${collectionPath}Marketing/Referrals/DataBase`, referral.id);
    try {
      await updateDoc(referralDocRef, referral);
      toast.success("Referral saved!");
    } catch (error) {
      toast.error("Failed to save referral.");
      console.error("Save Referral Error:", error);
    }
  };

  const handleDelete = async (referralId) => {
    const confirmed = window.confirm("Are you sure you want to delete this referral?");
    if (confirmed) {
      try {
        const referralDocRef = doc(db, `${collectionPath}Marketing/Referrals/DataBase`, referralId);
        await deleteDoc(referralDocRef);
        setReferrals((prev) => prev.filter((referral) => referral.id !== referralId));
        toast.success("Referral deleted!");
      } catch (error) {
        toast.error("Failed to delete referral.");
        console.error("Delete Referral Error:", error);
      }
    }
  };

  return loading ? (
    <div>Loading....</div>
  ) : (
    <div>
      <h3>Referral System</h3>
      <TableContainer component={Paper}>
        <ToastContainer position="top-center" autoClose={3000} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Referral Trigger</TableCell>
              <TableCell>Referral Amount ($)</TableCell>
              <TableCell>Referee Trigger</TableCell>
              <TableCell>Referee Amount ($)</TableCell>
              <TableCell>Active</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals.map((referral, index) => (
              <TableRow key={referral.id}>
                <TableCell>
                  <TextField name="name" value={referral.name} onChange={(e) => handleInputChange(e, index)} variant="outlined" size="small" />
                </TableCell>
                <TableCell>
                  <Select name="referralTrigger" value={referral.referralTrigger} onChange={(e) => handleInputChange(e, index)} variant="outlined" size="small">
                    {globalOptions.map((option) => (
                      <MenuItem key={option.action} value={option.action}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    name="referralAmount"
                    type="number"
                    value={referral.referralAmount}
                    onChange={(e) => handleInputChange(e, index)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Select name="refereeTrigger" value={referral.refereeTrigger} onChange={(e) => handleInputChange(e, index)} variant="outlined" size="small">
                    {globalOptions.map((option) => (
                      <MenuItem key={option.action} value={option.action}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    name="refereeAmount"
                    type="number"
                    value={referral.refereeAmount}
                    onChange={(e) => handleInputChange(e, index)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Checkbox checked={referral.active} onChange={() => handleToggleActive(index)} />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleSave(referral)} color="primary">
                    <Save />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(referral.id)} color="secondary">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {!isAddingNew ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <Button onClick={startAddingNew} startIcon={<Add />} variant="contained" color="primary">
                    Add New Referral
                  </Button>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>
                  <TextField
                    placeholder="Name"
                    value={newReferral.name}
                    onChange={(e) => setNewReferral({ ...newReferral, name: e.target.value })}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Select
                    name="referralTrigger"
                    value={newReferral.referralTrigger}
                    onChange={(e) => setNewReferral({ ...newReferral, referralTrigger: e.target.value })}
                    variant="outlined"
                    size="small"
                  >
                    {globalOptions.map((option) => (
                      <MenuItem key={option.action} value={option.action}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    placeholder="Referral Amount"
                    type="number"
                    value={newReferral.referralAmount}
                    onChange={(e) => setNewReferral({ ...newReferral, referralAmount: parseFloat(e.target.value) })}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Select
                    name="refereeTrigger"
                    value={newReferral.refereeTrigger}
                    onChange={(e) => setNewReferral({ ...newReferral, refereeTrigger: e.target.value })}
                    variant="outlined"
                    size="small"
                  >
                    {globalOptions.map((option) => (
                      <MenuItem key={option.action} value={option.action}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    placeholder="Referee Amount"
                    type="number"
                    value={newReferral.refereeAmount}
                    onChange={(e) => setNewReferral({ ...newReferral, refereeAmount: parseFloat(e.target.value) })}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Checkbox checked={newReferral.active} onChange={(e) => setNewReferral({ ...newReferral, active: e.target.checked })} />
                </TableCell>
                <TableCell>
                  <Button onClick={handleSaveNewReferral} startIcon={<Save />} variant="contained" color="primary">
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReferralSystem;
