import React, { useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { doc, runTransaction, collection } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const NewBrand = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore(); // Get Firestore instance from context
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [brandData, setBrandData] = useState({
    brand_uid: "",
    brand_name: "",
    brand_description: "",
    brand_categories: [], // Comma-separated input, converted to array
    rating: {
      votes: 0,
      rating: 0.0,
    },
    likes: 0,
  });
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const brandsCollectionRef = collection(db, `${collectionPath}Brands/BrandsList/DataBase`);
    const brandsIncrementRef = doc(db, `${collectionPath}Brands/BrandsList`); // Reference to the counter document

    try {
      const newBrandResult = await runTransaction(db, async (transaction) => {
        let newIncrement = 1;
        const incrementDoc = await transaction.get(brandsIncrementRef);
        if (incrementDoc.exists()) {
          newIncrement = (incrementDoc.data().brandsIncrement || 0) + 1;
          transaction.update(brandsIncrementRef, {
            brandsIncrement: newIncrement,
          });
        } else {
          transaction.set(brandsIncrementRef, { brandsIncrement: 1 });
        }

        let categories = [];
        if (brandData.brand_categories && brandData.brand_categories.length > 0) {
          if (brandData.brand_categories.includes(",")) {
            categories = brandData.brand_categories
              .split(",")
              .map((item) => item.trim())
              .filter((item) => item !== "");
          } else {
            categories = [brandData.brand_categories];
          }
        }

        const newBrand = {
          _collection: "brand",
          brand_uid: parseInt(newIncrement),
          brand_name: brandData.brand_name,
          brand_description: brandData.brand_description,
          brand_category: categories,
          rating: {
            votes: 0,
            rating: 0.0,
          },
          likes: 0,
        };

        const newDocRef = doc(brandsCollectionRef);
        transaction.set(newDocRef, newBrand);

        return newDocRef.id;
      });

      console.log("Brand added successfully with UID:", newBrandResult);
      // Reset form and loading state here
      setBrandData({
        brand_uid: "",
        brand_name: "",
        brand_description: "",
        brand_categories: [],
        rating: {
          votes: 0,
          rating: 0.0,
        },
        likes: 0,
      });
      navigate(`/main/brands/edit/${newBrandResult}`);
    } catch (error) {
      console.error("Transaction failed: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle category input transformation (comma-separated string to array)
  const handleCategoryChange = (e) => {
    const input = e.target.value;
    const categoryArray = input
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item !== ""); // Split, trim, and filter
    setBrandData({ ...brandData, brand_categories: categoryArray });
  };

  return (
    <div>
      <h2>Insert New Brand</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Brand Name:</label>
            <input
              type="text"
              value={brandData.brand_name}
              onChange={(e) => setBrandData({ ...brandData, brand_name: e.target.value })}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Description:</label>
            <textarea
              value={brandData.brand_description}
              onChange={(e) =>
                setBrandData({
                  ...brandData,
                  brand_description: e.target.value,
                })
              }
              style={styles.formInput}
            ></textarea>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Categories:</label>
            <input
              type="text"
              value={brandData.brand_categories}
              onChange={(e) => setBrandData({ ...brandData, brand_categories: e.target.value })} // Handle category input as an array
              style={styles.formInput}
            />
          </div>
          <button
            type="submit"
            style={{
              ...styles.button,
              backgroundColor: loading ? "silver" : "#478169",
            }}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
};

export default NewBrand;
