import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { doc, collection, getDocs, getDoc, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import config from "../../../config";
import { ToastContainer, toast } from "react-toastify";
import { TableSortLabel } from "@mui/material";
import { EnvContext } from "../../../context/EnvContext";

import "react-toastify/dist/ReactToastify.css";

const EditStore = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore(); // Get Firestore instance from context
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [stores, setStores] = useState([]);
  const [storeData, setStoreData] = useState({
    seller_uid: "",
    seller_name: "",
    seller_description: "",
    contacts: {
      phoneNumber: "",
      email: "",
      web: "",
    },
    location: {
      address: "",
      city: "",
      zip_code: "",
    },
    delivery: {
      description: "",
    },
    payment: {
      venmo: "",
      square: "",
    },
    images: [],
  });
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("form");
  const [files, setFiles] = useState([]);
  const [products, setProducts] = useState([]); // Added for products
  const [selectAll, setSelectAll] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: "product_name",
    direction: "asc",
  });

  const fileInputRef = useRef(null);

  const formatUID = (uid, totalLength = 8) => {
    return String(uid).padStart(totalLength, "0");
  };

  useEffect(() => {
    if (id) {
      // Fetch single store from Firestore
      const storeDocRef = doc(db, `${collectionPath}Stores/StoresList/DataBase`, id);

      getDoc(storeDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();

            // Set store data to populate form fields
            const storeData = {
              seller_uid: data.seller_uid,
              seller_name: data.seller_name,
              images: data.images,
              seller_description: data.seller_description,
              contacts: {
                email: data.contacts.email,
                phoneNumber: data.contacts.phoneNumber,
                web: data.contacts.web,
              },
              delivery: {
                description: data.delivery.description,
              },
              location: {
                address: data.location.address,
                city: data.location.city,
                zip_code: data.location.zip_code,
              },
              payment: {
                venmo: data.payment.venmo,
                square: data.payment.square,
              },
            };
            setStoreData(storeData);
            console.log("Store data fetched successfully", data.name);
          } else {
            console.error("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error fetching store:", error);
        });
    } else {
      // Fetch all stores from Firestore
      const storesCollectionRef = collection(db, `${collectionPath}Stores/StoresList/DataBase`);

      getDocs(storesCollectionRef)
        .then((querySnapshot) => {
          const storesArray = [];
          querySnapshot.forEach((doc) => {
            storesArray.push({ id: doc.id, ...doc.data() });
          });

          setStores(storesArray);
        })
        .catch((error) => {
          console.error("Error fetching stores:", error);
        });
    }
  }, [db, id, collectionPath]);

  const sortedProducts = [...products].sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    const aValue = sortConfig.key === "brand_name" ? a.brand_name : a[sortConfig.key];
    const bValue = sortConfig.key === "brand_name" ? b.brand_name : b[sortConfig.key];

    if (aValue < bValue) {
      return -1 * order;
    }
    if (aValue > bValue) {
      return 1 * order;
    }
    return 0;
  });

  // Fetch products and listings on page load
  useEffect(() => {
    const fetchProductsAndListings = async () => {
      const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);
      const productsSnapshot = await getDocs(productsCollectionRef);
      const productsList = productsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const listingsCollectionRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
      const listingsSnapshot = await getDocs(listingsCollectionRef);

      // Extract document IDs which are in the format seller_id_product_id
      const listedDocIds = listingsSnapshot.docs.map((doc) => doc.id);

      // Mark products that are already listed
      const productsWithCheckedState = productsList.map((product) => {
        const docId = `${id}_${product.id}`; // Create the expected docId format
        return {
          ...product,
          isChecked: listedDocIds.includes(docId), // Check if this docId exists in the listings
        };
      });

      setProducts(productsWithCheckedState); // Set the fetched products with checked state
    };

    fetchProductsAndListings();
  }, [db, id]); // Ensure that id and db are in the dependency array

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storesCollectionRef = collection(db, `${collectionPath}Stores/StoresList/DataBase`);
      const storeDocRef = doc(storesCollectionRef, id);

      const updatedStore = {
        _collection: "store",
        contacts: {
          email: storeData.contacts.email || "",
          phoneNumber: storeData.contacts.phoneNumber || "",
          web: storeData.contacts.web || "",
        },
        delivery: {
          description: storeData.delivery.description || "",
        },
        location: {
          address: storeData.location.address || "",
          city: storeData.location.city || "",
          zip_code: storeData.location.zip_code || "",
        },
        seller_description: storeData.seller_description,
        seller_name: storeData.seller_name,
        payment: {
          venmo: storeData.payment.venmo || "",
          square: storeData.payment.square || "",
        },
        date_of_update: new Date().toISOString(),
      };

      updateDoc(storeDocRef, updatedStore)
        .then(() => {
          toast.success("Store updated successfully");
          navigate(`/main/stores/edit/${id}`);
        })
        .catch((error) => {
          toast.error("Error updating store");
          console.error("Error updating store:", error);
        });
    } catch (error) {
      toast.error("Error updating store");
      console.error("Error updating store:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    setLoading(true);

    let updatedImages = [...(storeData.images || [])]; // Start with current images

    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file);

      console.log("Uploading image:", file.name);

      try {
        const apiUrl = `${config.apiConfig.API_ENDPOINT_URL}${location.pathname.startsWith("/_DEV_") ? "/api/dev" : "/api"}/store/storeImages/${id}`;

        alert(apiUrl);

        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });
        const result = await response.json();

        // Check if the response is okay and imageUrls is an array
        if (response.ok && Array.isArray(result.imageUrls)) {
          updatedImages = [...updatedImages, ...result.imageUrls]; // Append new image URLs to the array
        } else {
          // Handle cases where imageUrls is not an array or response is not OK
          throw new Error(`Failed to upload: ${file.name}, server responded with: ${response.status} and message: ${JSON.stringify(result)}`);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        alert("Error uploading image - " + error.message);
      }
    }
    setTimeout(() => {
      setStoreData((prevState) => ({
        ...prevState,
        images: updatedImages,
      }));
      // clear image input and loading state
      fileInputRef.current.value = "";
      setLoading(false);
    }, 1500);
  };

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };

  const handleTabClick = (tab) => {
    setCurrentTab(tab);
  };

  const deleteImage = async (imageIndex) => {
    setLoading(true);

    const updatedImages = storeData.images.filter((_, index) => index !== imageIndex);

    try {
      const storeDocRef = doc(db, `${collectionPath}Stores/StoresList/DataBase`, id);
      await updateDoc(storeDocRef, {
        images: updatedImages,
      });
      setStoreData({ ...storeData, images: updatedImages });
      alert("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Error deleting image");
    } finally {
      setLoading(false);
    }
  };

  //
  // Handle product checkbox change
  const handleCheckboxChange = (productId) => {
    setProducts((prevProducts) => prevProducts.map((product) => (product.id === productId ? { ...product, isChecked: !product.isChecked } : product)));
  };

  // Handle select all/unselect all products
  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    setProducts((prevProducts) => prevProducts.map((product) => ({ ...product, isChecked: !selectAll })));
  };

  // Handle save selected products to Firestore

  const handleSortRequest = (property) => {
    const isAsc = sortConfig.key === property && sortConfig.direction === "asc";
    setSortConfig({ key: property, direction: isAsc ? "desc" : "asc" });
  };

  const handleSaveProducts = async () => {
    for (const product of products) {
      const docId = `${id}_${product.id}`; // Unique ID for the listing
      const listingRef = doc(db, `${collectionPath}Listings/ListingsList/DataBase`, docId);

      if (product.isChecked) {
        try {
          // Check if the listing already exists
          const listingDoc = await getDoc(listingRef);
          let existingData = {};

          if (listingDoc.exists()) {
            // If the listing exists, fetch the existing data
            existingData = listingDoc.data();
          }

          // Merge existing data with new data to avoid overwriting fields
          const dataToSave = {
            ...existingData, // Preserve existing fields like price, etc.
            ...product, // Add or update the new product data
            _collection: "listing",
            seller_id: id,
            seller_name: storeData?.seller_name,
            seller_uid: storeData?.seller_uid,
            product_id: product.id,
            seller_product_id: docId,
          };

          // Save the merged data
          await setDoc(listingRef, dataToSave);
        } catch (error) {
          console.error("Error saving product:", error);
          toast.error(`Error saving product ${product.product_name}`);
        }
      } else {
        // Remove unchecked product from listings
        await deleteDoc(listingRef);
      }
    }
    toast.success("Products updated successfully");
  };

  if (id) {
    // If ID is provided, display the form
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <h2>Edit Seller</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: 20,
          }}
        >
          <div
            onClick={() => handleTabClick("form")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab === "form" ? "#ff0000" : "#d5d4d4",
              color: currentTab === "form" ? "white" : "gray",
            }}
          >
            Edit Seller Data
          </div>
          <div
            onClick={() => handleTabClick("sellersProducts")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab === "sellersProducts" ? "#ff0000" : "#d5d4d4",
              color: currentTab === "sellersProducts" ? "white" : "gray",
            }}
          >
            Edit Seller Products
          </div>
          <div
            onClick={() => handleTabClick("images")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab === "images" ? "#ff0000" : "#d5d4d4",
              color: currentTab === "images" ? "white" : "gray",
            }}
          >
            Edit Seller Images
          </div>
        </div>
        {currentTab === "form" && (
          <form onSubmit={handleSubmit} style={styles.form}>
            <div style={{ padding: "20px" }}>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Seller UID:</label>
                <input
                  type="text"
                  value={`S${formatUID(storeData.seller_uid)}`}
                  onChange={(e) => setStoreData({ ...storeData, seller_uid: e.target.value })}
                  style={styles.formInput}
                  disabled
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Seller Name:</label>
                <input
                  type="text"
                  value={storeData.seller_name}
                  onChange={(e) => setStoreData({ ...storeData, seller_name: e.target.value })}
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Description:</label>
                <textarea
                  value={storeData.seller_description}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      seller_description: e.target.value,
                    })
                  }
                  style={styles.formInput}
                ></textarea>
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Contact Number:</label>
                <input
                  type="text"
                  value={storeData.contacts.phoneNumber}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      contacts: {
                        ...storeData.contacts,
                        phoneNumber: e.target.value,
                      },
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Email:</label>
                <input
                  type="text"
                  value={storeData.contacts.email}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      contacts: {
                        ...storeData.contacts,
                        email: e.target.value,
                      },
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Web:</label>
                <input
                  type="text"
                  value={storeData.contacts.web}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      contacts: { ...storeData.contacts, web: e.target.value },
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Location (Address, City, Zip Code):</label>
                <input
                  type="text"
                  value={storeData.location.address}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      location: {
                        ...storeData.location,
                        address: e.target.value,
                      },
                    })
                  }
                  placeholder="Address"
                  style={styles.formInput}
                />
                <input
                  type="text"
                  value={storeData.location.city}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      location: { ...storeData.location, city: e.target.value },
                    })
                  }
                  placeholder="City"
                  style={styles.formInput}
                />
                <input
                  type="text"
                  value={storeData.location.zip_code}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      location: {
                        ...storeData.location,
                        zip_code: e.target.value,
                      },
                    })
                  }
                  placeholder="Zip Code"
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Delivery Description:</label>
                <input
                  type="text"
                  value={storeData.delivery.description}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      delivery: {
                        ...storeData.delivery,
                        description: e.target.value,
                      },
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <label style={styles.formLabel}>Payment (Venmo, Square):</label>
                <input
                  type="text"
                  value={storeData.payment.venmo}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      payment: { ...storeData.payment, venmo: e.target.value },
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <div style={styles.formElement}>
                <input
                  type="text"
                  value={storeData.payment.square}
                  onChange={(e) =>
                    setStoreData({
                      ...storeData,
                      payment: { ...storeData.payment, square: e.target.value },
                    })
                  }
                  style={styles.formInput}
                />
              </div>
              <button
                type="submit"
                style={{
                  ...styles.button,
                  backgroundColor: loading ? "silver" : "#478169",
                }}
              >
                {loading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        )}
        {currentTab === "images" && (
          <form onSubmit={handleImageUpload} style={styles.form}>
            <div style={{ padding: 20 }}>
              <div
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  display: "flex",
                  marginBottom: 50,
                }}
              >
                {storeData.images &&
                  storeData.images.map((image, index) => (
                    <div key={index} style={{ flexDirection: "column", marginBottom: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 10,
                          padding: 2,
                          border: "1px solid silver",
                          marginRight: 10,
                        }}
                      >
                        <img
                          src={image}
                          alt={`Store ${index}`}
                          style={{
                            width: 150,
                            height: 150,
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div
                        onClick={() => deleteImage(index)}
                        style={{
                          fontSize: 12,
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  ))}
              </div>

              <div style={styles.formElement}>
                <label style={styles.formLabel}>Upload Image:</label>
                <input ref={fileInputRef} type="file" onChange={handleFileChange} style={styles.formInput} accept="image/*" multiple />
              </div>
              <button
                type="submit"
                style={{
                  ...styles.button,
                  backgroundColor: loading ? "silver" : "#478169",
                }}
              >
                {loading ? "Uploading..." : "Upload Image"}
              </button>
            </div>
          </form>
        )}
        {currentTab === "sellersProducts" && (
          <div style={styles.form}>
            <div style={{ padding: 20 }}>
              <h2>Edit Seller Products</h2>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 30,
                }}
              >
                <div onClick={handleSelectAll}>
                  <u>{selectAll ? "Unselect All" : "Select All"}</u>
                </div>
              </div>
              <table style={styles.table}>
                <thead style={{ borderBottom: ".5px solid silver" }}>
                  <tr>
                    <th style={{ textAlign: "left", paddingBottom: "10px" }}>
                      <TableSortLabel
                        active={sortConfig.key === "product_name"}
                        direction={sortConfig.key === "product_name" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortRequest("product_name")}
                      >
                        Name
                      </TableSortLabel>
                    </th>
                    <th style={{ textAlign: "left" }}>
                      <TableSortLabel
                        active={sortConfig.key === "brand_name"}
                        direction={sortConfig.key === "brand_name" ? sortConfig.direction : "asc"}
                        onClick={() => handleSortRequest("brand_name")}
                      >
                        Brand
                      </TableSortLabel>
                    </th>
                    <th style={{ textAlign: "right" }}>Check</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedProducts.map((product) => (
                    <tr key={product.id} style={{ borderBottom: "1px solid #ebe8e9" }}>
                      <td style={{ padding: "10px 0px" }}>{product.product_name}</td>
                      <td>{product.brand_name}</td>
                      <td style={{ textAlign: "right" }}>
                        <input type="checkbox" checked={product.isChecked} onChange={() => handleCheckboxChange(product.id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button onClick={handleSaveProducts} style={styles.button}>
                Save Selected Products
              </button>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    // If no ID, display list of all stores
    return (
      <div>
        <h2>All Sellers</h2>
        <table style={styles.table}>
          <thead>
            <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
              <th style={{ ...styles.th, width: 150 }}>Seller UID</th>
              <th style={styles.th}>Name</th>
            </tr>
          </thead>
          <tbody>
            {stores.map((store) => (
              <tr
                key={store.id}
                style={styles.clickableRow}
                onClick={() => navigate(`/main/stores/edit/${store.id}`)} // Navigate to store details
              >
                <td style={styles.td}>S{formatUID(store.seller_uid)}</td>
                <td style={styles.tdName}>{store.seller_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    color: "gray",
  },
  tdName: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    cursor: "pointer",
  },
  clickableRow: {
    cursor: "pointer",
  },
};

export default EditStore;
