import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDoc, doc, collection, getDocs, deleteDoc, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import { EnvContext } from "../../../context/EnvContext";

const EditBrand = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore(); // Get Firestore instance from context
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("form");
  const [brandData, setBrandData] = useState({
    brand_uid: "",
    brand_name: "",
    images: [],
    brand_description: "",
    brand_category: [],
  });
  const [files, setFiles] = useState([]);

  const fileInputRef = useRef(null);

  const formatUID = (uid, totalLength = 8) => {
    return String(uid).padStart(totalLength, "0");
  };

  useEffect(() => {
    if (id) {
      // Fetch single brand from Firestore
      const brandDocRef = doc(db, `${collectionPath}Brands/BrandsList/DataBase`, id);

      getDoc(brandDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();

            setBrand(data);
            // Set brand data to populate form fields
            setBrandData({
              brand_uid: data.brand_uid,
              brand_name: data.brand_name,
              images: data.images,
              brand_description: data.brand_description,
              brand_category: Array.isArray(data.brand_category) ? data.brand_category : [data.brand_category],
            });
            console.log("Brand data fetched successfully", data.brand_name);
          } else {
            console.error("No such document!");
          }
        })
        .catch((error) => {
          console.error("Error fetching brand:", error);
        });
    } else {
      // Fetch all brands from Firestore
      const brandsCollectionRef = collection(db, `${collectionPath}Brands/BrandsList/DataBase`);

      getDocs(brandsCollectionRef)
        .then((querySnapshot) => {
          const brandsArray = [];
          querySnapshot.forEach((doc) => {
            brandsArray.push({ id: doc.id, ...doc.data() });
          });
          setBrands(brandsArray);
        })
        .catch((error) => {
          console.error("Error fetching brands:", error);
        });
    }
  }, [db, id, collectionPath]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const brandDocRef = doc(db, `${collectionPath}Brands/BrandsList/DataBase`, id);

      let categories = [];
      if (Array.isArray(brandData.brand_category)) {
        categories = brandData.brand_category;
      } else if (typeof brandData.brand_category === "string") {
        categories = brandData.brand_category
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item !== "");
      }

      const updatedBrand = {
        brand_uid: brandData.brand_uid,
        brand_name: brandData.brand_name,
        brand_description: brandData.brand_description,
        brand_category: categories,
      };

      updateDoc(brandDocRef, updatedBrand)
        .then(() => {
          toast.success("Brand updated successfully");
          navigate(`/main/brands/edit/${id}`);
        })
        .catch((error) => {
          toast.error("Error updating brand");
          console.error("Error updating brand:", error);
        });
    } catch (error) {
      toast.error("Error updating brand");
      console.error("Error updating brand:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    setLoading(true);

    let updatedImages = [...(brandData.images || [])]; // Start with current images

    for (const file of files) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const apiUrl = `${config.apiConfig.API_ENDPOINT_URL}${location.pathname.startsWith("/_DEV_") ? "/api/dev" : "/api"}/brand/brandImages/${id}`;

        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });
        const result = await response.json();

        // Check if the response is okay and imageUrls is an array
        if (response.ok && Array.isArray(result.imageUrls)) {
          updatedImages = [...updatedImages, ...result.imageUrls]; // Append new image URLs to the array
        } else {
          // Handle cases where imageUrls is not an array or response is not OK
          throw new Error(`Failed to upload: ${file.name}, server responded with: ${response.status} and message: ${JSON.stringify(result)}`);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Error uploading image - " + error.message);
      }
    }
    setTimeout(() => {
      setBrandData((prevState) => ({
        ...prevState,
        images: updatedImages,
      }));
      // clear image input and loading state
      fileInputRef.current.value = "";
      setLoading(false);
    }, 1500);
  };

  const handleFileChange = (event) => {
    setFiles([...event.target.files]);
  };
  const handleTabClick = (tab) => {
    setCurrentTab(tab);
  };

  const deleteImage = async (imageIndex) => {
    setLoading(true);

    const updatedImages = brandData.images.filter((_, index) => index !== imageIndex);

    try {
      const brandDocRef = doc(db, `${collectionPath}Brands/BrandsList/DataBase`, id);
      await updateDoc(brandDocRef, {
        images: updatedImages,
      });
      setBrandData({ ...brandData, images: updatedImages });
      toast.success("Image deleted successfully");
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error("Error deleting image");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (brandId) => {
    const confirmed = window.confirm("Are you sure you want to delete this brand? This action cannot be undone.");

    if (confirmed) {
      try {
        const brandDocRef = doc(db, `${collectionPath}Brands/BrandsList/DataBase`, brandId);
        await deleteDoc(brandDocRef);
        toast.success("Brand deleted successfully");

        // Update the brands list to remove the deleted brand from view
        setBrands((prevBrands) => prevBrands.filter((brand) => brand.id !== brandId));
        navigate(`/main/brands`);
      } catch (error) {
        console.error("Error deleting brand:", error);
        toast.error("Error deleting brand");
      }
    }
  };

  if (id) {
    // If ID is provided, display the form
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <h2 style={{ fontWeight: "200" }}>
          Edit Brand
          {brandData.brand_name && <span style={{ fontWeight: "400" }}>: {brandData.brand_name}</span>}
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginLeft: 20,
          }}
        >
          <div
            onClick={() => handleTabClick("form")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab == "form" ? "#ff0000" : "#d5d4d4",
              color: currentTab == "form" ? "white" : "gray",
            }}
          >
            Edit Brand Data
          </div>
          <div
            onClick={() => handleTabClick("images")}
            style={{
              ...styles.tabButton,
              backgroundColor: currentTab == "images" ? "#ff0000" : "#d5d4d4",
              color: currentTab == "images" ? "white" : "gray",
            }}
          >
            Edit Brand Images
          </div>
        </div>

        {
          //
          // FORM

          currentTab === "form" && (
            <form onSubmit={handleSubmit} style={styles.form}>
              <div style={{ padding: "20px" }}>
                <div style={styles.formElement}>
                  <label style={styles.formLabel}>Brand UID:</label>
                  <input
                    type="text"
                    value={`B${formatUID(brandData.brand_uid)}`}
                    onChange={(e) => setBrandData({ ...brandData, brand_uid: e.target.value })}
                    style={styles.formInput}
                    disabled
                  />
                </div>
                <div style={styles.formElement}>
                  <label style={styles.formLabel}>Brand Name:</label>
                  <input
                    type="text"
                    value={brandData.brand_name}
                    onChange={(e) => setBrandData({ ...brandData, brand_name: e.target.value })}
                    style={styles.formInput}
                  />
                </div>
                <div style={styles.formElement}>
                  <label style={styles.formLabel}>Description:</label>
                  <textarea
                    value={brandData.brand_description}
                    onChange={(e) =>
                      setBrandData({
                        ...brandData,
                        brand_description: e.target.value,
                      })
                    }
                    style={styles.formInput}
                  ></textarea>
                </div>
                <div style={styles.formElement}>
                  <label style={styles.formLabel}>Categories:</label>
                  <input
                    type="text"
                    value={brandData.brand_category}
                    onChange={(e) =>
                      setBrandData({
                        ...brandData,
                        brand_category: e.target.value,
                      })
                    } // Handle category input as an array
                    style={styles.formInput}
                  />
                </div>
                <div
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      ...styles.button,
                      backgroundColor: loading ? "silver" : "#478169",
                    }}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>

                  <button
                    onClick={() => handleDelete(id)}
                    style={{
                      ...styles.button,
                      ...styles.deleteButton,
                      backgroundColor: loading ? "silver" : "red",
                      color: "white",
                      marginLeft: 20,
                      marginTop: 20,
                    }}
                  >
                    {loading ? "Deleting..." : "Delete Brand"}
                  </button>
                </div>
              </div>
            </form>
          )
        }
        {
          //
          // IMAGES

          currentTab === "images" && (
            <form onSubmit={handleImageUpload} style={styles.form}>
              <div style={{ padding: 20 }}>
                <div
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    display: "flex",
                    marginBottom: 50,
                  }}
                >
                  {brandData.images &&
                    brandData.images.map((image, index) => (
                      <div key={index} style={{ flexDirection: "column", marginBottom: 15 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                            padding: 2,
                            border: "1px solid silver",
                            marginRight: 10,
                          }}
                        >
                          <img
                            src={image}
                            style={{
                              width: 150,
                              height: 150,
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div
                          onClick={() => deleteImage(index)}
                          style={{
                            fontSize: 12,
                            color: "red",
                            cursor: "pointer",
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    ))}
                </div>

                <div style={styles.formElement}>
                  <label style={styles.formLabel}>Upload Image:</label>
                  <input ref={fileInputRef} type="file" onChange={handleFileChange} style={styles.formInput} accept="image/*" multiple />
                </div>
                <button
                  type="submit"
                  style={{
                    ...styles.button,
                    backgroundColor: loading ? "silver" : "#478169",
                  }}
                >
                  {loading ? "Uploading..." : "Upload Image"}
                </button>
              </div>
            </form>
          )
        }
      </div>
    );
  } else {
    // If no ID, display list of all brands
    return (
      <div>
        <h2>All Brands</h2>
        <table style={styles.table}>
          <thead>
            <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
              <th style={{ ...styles.th, width: "120px" }}>Brand UID</th>
              <th style={styles.th}>Name</th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand) => (
              <tr key={brand.id} style={styles.clickableRow}>
                <td style={styles.td}>{`B${formatUID(brand.brand_uid)}`}</td>
                <td style={styles.tdName} onClick={() => navigate(`/main/brands/edit/${brand.id}`)}>
                  {brand.brand_name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  deleteButton: {
    padding: "8px 15px",
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "12px",
  },
  //
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  //
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    color: "gray",
  },
  tdName: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    cursor: "pointer",
  },
  clickableRow: {
    cursor: "pointer",
  },
};

export default EditBrand;
