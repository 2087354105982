import React, { useEffect, useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, getDocs, doc, addDoc, updateDoc, deleteDoc, Timestamp } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, IconButton, Checkbox } from "@mui/material";
import { Add, Save, Delete } from "@mui/icons-material";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const DiscountCodes = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore(); // Firestore instance
  const [discountCodes, setDiscountCodes] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false); // Track if we're adding a new discount code
  const [newDiscount, setNewDiscount] = useState({
    code: "",
    amount: "",
    startDate: "",
    endDate: "",
    active: false,
  });
  const [loading, setLoading] = useState(false);

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // Fetch discount codes on mount
  const fetchDiscountCodes = async () => {
    setLoading(true);
    const discountsCollection = collection(db, `${collectionPath}Marketing/DiscountsCodes/DataBase`);
    const snapshot = await getDocs(discountsCollection);
    const codes = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        startDate: data.startDate?.toDate().toISOString().split("T")[0] || "", // Convert to YYYY-MM-DD
        endDate: data.endDate?.toDate().toISOString().split("T")[0] || "", // Convert to YYYY-MM-DD
      };
    });
    setDiscountCodes(codes);
    setLoading(false);
  };

  useEffect(() => {
    fetchDiscountCodes();
  }, [db, collectionPath]);

  // Handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedCodes = [...discountCodes];
    updatedCodes[index][name] = name === "amount" ? parseInt(value) : value;
    setDiscountCodes(updatedCodes);
  };

  // Toggle code activation
  const handleToggleActive = (index) => {
    const updatedCodes = [...discountCodes];
    updatedCodes[index].active = !updatedCodes[index].active;
    setDiscountCodes(updatedCodes);
  };

  // Start adding a new discount code
  const startAddingNew = () => {
    setIsAddingNew(true);
    setNewDiscount({ code: "", amount: "", startDate: "", endDate: "", active: false });
  };

  // Save new discount code
  const handleSaveNewDiscount = async () => {
    if (!newDiscount.startDate || !newDiscount.endDate) {
      toast.error("Both start and end dates are required.");
      return;
    }

    const discountData = {
      ...newDiscount,
      startDate: Timestamp.fromDate(new Date(newDiscount.startDate)),
      endDate: Timestamp.fromDate(new Date(newDiscount.endDate)),
    };

    try {
      const discountsCollection = collection(db, `${collectionPath}Marketing/DiscountsCodes/DataBase`);
      await addDoc(discountsCollection, discountData);
      toast.success("Discount code added!");
      setIsAddingNew(false);
      setNewDiscount({ code: "", amount: "", startDate: "", endDate: "", active: false });
      fetchDiscountCodes();
    } catch (error) {
      toast.error("Failed to add new discount code.");
      console.error("Add Discount Error:", error);
    }
  };

  // Save existing discount code
  const handleSave = async (code) => {
    if (!code.startDate || !code.endDate) {
      toast.error("Both start and end dates are required.");
      return;
    }

    const codeData = {
      ...code,
      startDate: Timestamp.fromDate(new Date(code.startDate)),
      endDate: Timestamp.fromDate(new Date(code.endDate)),
    };

    const codeDocRef = doc(db, `${collectionPath}Marketing/DiscountsCodes/DataBase`, code.id);
    try {
      await updateDoc(codeDocRef, codeData);
      toast.success("Discount code saved!");
    } catch (error) {
      toast.error("Failed to save discount code.");
      console.error("Save Discount Error:", error);
    }
  };

  // Delete discount code
  const handleDelete = async (codeId) => {
    const confirmed = window.confirm("Are you sure you want to delete this discount code?");
    if (confirmed) {
      try {
        const codeDocRef = doc(db, `${collectionPath}Marketing/DiscountsCodes/DataBase`, codeId);
        await deleteDoc(codeDocRef);
        setDiscountCodes((prev) => prev.filter((code) => code.id !== codeId));
        toast.success("Discount code deleted!");
      } catch (error) {
        toast.error("Failed to delete discount code.");
        console.error("Delete Discount Error:", error);
      }
    }
  };

  return loading ? (
    <div
      style={{
        dispaly: "flex",
        textAlign: "center",
        alignContent: "center",
        height: "100vh",
        flex: 1,
        width: "100%",
      }}
    >
      Loading....
    </div>
  ) : (
    <div>
      <h3>Product Marketing</h3>
      <TableContainer component={Paper}>
        <ToastContainer position="top-center" autoClose={3000} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Amount (%)</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discountCodes.map((code, index) => (
              <TableRow key={code.id}>
                <TableCell>
                  <TextField name="code" value={code.code} onChange={(e) => handleInputChange(e, index)} variant="outlined" size="small" />
                </TableCell>
                <TableCell>
                  <TextField name="amount" type="number" value={code.amount} onChange={(e) => handleInputChange(e, index)} variant="outlined" size="small" />
                </TableCell>
                <TableCell>
                  <TextField
                    name="startDate"
                    type="date"
                    value={code.startDate}
                    onChange={(e) => handleInputChange(e, index)}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField name="endDate" type="date" value={code.endDate} onChange={(e) => handleInputChange(e, index)} variant="outlined" size="small" />
                </TableCell>
                <TableCell>
                  <Checkbox checked={code.active} onChange={() => handleToggleActive(index)} />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleSave(code)} color="primary">
                    <Save />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(code.id)} color="secondary">
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {!isAddingNew ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Button onClick={startAddingNew} startIcon={<Add />} variant="contained" color="primary">
                    Add New Discount Code
                  </Button>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>
                  <TextField
                    placeholder="Code"
                    value={newDiscount.code}
                    onChange={(e) => setNewDiscount({ ...newDiscount, code: e.target.value })}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    placeholder="Amount"
                    type="number"
                    value={newDiscount.amount}
                    onChange={(e) => setNewDiscount({ ...newDiscount, amount: parseInt(e.target.value) })}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    placeholder="Start Date"
                    type="date"
                    value={newDiscount.startDate}
                    onChange={(e) => setNewDiscount({ ...newDiscount, startDate: e.target.value })}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    placeholder="End Date"
                    type="date"
                    value={newDiscount.endDate}
                    onChange={(e) => setNewDiscount({ ...newDiscount, endDate: e.target.value })}
                    variant="outlined"
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Checkbox checked={newDiscount.active} onChange={(e) => setNewDiscount({ ...newDiscount, active: e.target.checked })} />
                </TableCell>
                <TableCell>
                  <Button onClick={handleSaveNewDiscount} startIcon={<Save />} variant="contained" color="primary">
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DiscountCodes;
