import React, { createContext, useContext } from "react";

// Create a context for Firestore and Functions
const FirestoreContext = createContext(null);

export const FirestoreContextProvider = ({ db, functions, children }) => {
  return <FirestoreContext.Provider value={{ db, functions }}>{children}</FirestoreContext.Provider>;
};

// Custom hook to access Firestore instance
export const useFirestore = () => {
  const context = useContext(FirestoreContext);
  if (!context) {
    throw new Error("useFirestore must be used within a FirestoreContextProvider");
  }
  return context.db; // Return only the Firestore instance
};

// Custom hook to access Functions instance
export const useFunctions = () => {
  const context = useContext(FirestoreContext);
  if (!context) {
    throw new Error("useFunctions must be used within a FirestoreContextProvider");
  }
  return context.functions; // Return only the Functions instance
};
