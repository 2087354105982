import React, { useEffect, useState, useContext } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { getDocs, collection } from "firebase/firestore";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const formatUID = (uid, totalLength = 8) => {
  return String(uid).padStart(totalLength, "0");
};

const Listings = () => {
  const environment = useContext(EnvContext);
  const location = useLocation();
  const navigate = useNavigate();
  const db = useFirestore();

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStores = async () => {
      setLoading(true);
      try {
        const storesCollectionRef = collection(db, `${collectionPath}Stores/StoresList/DataBase`);
        const querySnapshot = await getDocs(storesCollectionRef);
        const storesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setStores(storesData);
      } catch (error) {
        console.error("Error fetching stores:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStores();
  }, [db, collectionPath]);

  // Check if we are on an edit route or the main listings route
  const isEditPage = location.pathname.includes("edit");
  const isSooperDealsPage = location.pathname.includes("sooper-deals");

  return (
    <div>
      {/* Show the stores list ONLY if we are not on the edit page */}
      {!isEditPage && !isSooperDealsPage && (
        <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div style={{ padding: "10px" }}>
              {stores.length > 0 ? (
                stores.map((store) => (
                  <div key={store.id} style={styles.storeListItem} onClick={() => navigate(`/main/listings/edit/${store.id}`)}>
                    <div
                      style={{
                        width: "150px",
                        color: "silver",
                      }}
                    >{`S${formatUID(store.seller_uid)}`}</div>
                    <div>{store.seller_name}</div>
                  </div>
                ))
              ) : (
                <div>No stores available.</div>
              )}
            </div>
          )}
        </div>
      )}

      {/* The Outlet will be used to render the product table in the edit page */}
      <Outlet />
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "white",
    borderBottom: "1px solid #ccc",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px",
  },
  storeListItem: {
    display: "flex",
    padding: "10px 0",
    borderBottom: ".5px solid silver",
    width: "100%",
    cursor: "pointer",
  },
};

export default Listings;
