import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { doc, getDoc, setDoc, updateDoc, deleteField } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const Shipment = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();

  // Determine the base path based on the environment

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [settingsData, setSettingsData] = useState({
    perishablePrice: "",
    shipmentPrice: "",
    freeShipmentThreshold: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings`, "Shipment");
      const docSnap = await getDoc(settingsDocRef);

      if (docSnap.exists()) {
        setSettingsData(docSnap.data());
      } else {
        console.error("No such document!");
      }
    };

    fetchSettings();
  }, [db, collectionPath]);

  //
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate price format
    const pricePattern = /^$|^\d+(\.\d{1,2})?$/;

    if (!pricePattern.test(settingsData.perishablePrice)) {
      toast.error("Perishable Price must be in the format 00.00");
      setLoading(false);
      return;
    }
    if (!pricePattern.test(settingsData.shipmentPrice)) {
      toast.error("Shipment Price must be in the format 00.00");
      setLoading(false);
      return;
    }
    if (!pricePattern.test(settingsData.freeShipmentThreshold)) {
      toast.error("Free Shipment Threshold must be in the format 00.00");
      setLoading(false);
      return;
    }

    // Construct the updated data object
    let updatedSettingsData = {
      ...settingsData,
      perishablePrice: settingsData.perishablePrice ? parseFloat(settingsData.perishablePrice) : null,
      shipmentPrice: settingsData.shipmentPrice ? parseFloat(settingsData.shipmentPrice) : null,
      freeShipmentThreshold: settingsData.freeShipmentThreshold ? parseFloat(settingsData.freeShipmentThreshold) : null,
    };

    // Prepare an object for deleting fields in Firestore
    let fieldsToDelete = {};

    Object.keys(updatedSettingsData).forEach((key) => {
      if (updatedSettingsData[key] === null || updatedSettingsData[key] === "" || isNaN(updatedSettingsData[key])) {
        fieldsToDelete[key] = deleteField(); // Mark the field for deletion
        delete updatedSettingsData[key]; // Also remove it from the update object
      }
    });

    const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings`, "Shipment");

    try {
      const docSnap = await getDoc(settingsDocRef);
      if (docSnap.exists()) {
        // If fields need to be deleted, run an update for deletion first
        if (Object.keys(fieldsToDelete).length > 0) {
          await updateDoc(settingsDocRef, fieldsToDelete);
        }
        // Update or create the document with non-deleted fields
        if (Object.keys(updatedSettingsData).length > 0) {
          await updateDoc(settingsDocRef, updatedSettingsData);
        }
      } else {
        await setDoc(settingsDocRef, updatedSettingsData); // Create the document if it doesn't exist
      }
      toast.success("Settings saved successfully");
    } catch (error) {
      toast.error(`Error saving settings: ${error.message}`);
      console.error("Error updating Sooperstock settings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettingsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      <h2 style={{ fontWeight: 200 }}>Shipment Settings</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Shipment Price:</label>
            <input
              type="text"
              name="shipmentPrice"
              value={settingsData.shipmentPrice}
              onChange={handleChange}
              style={styles.formInput}
              pattern="\d+(\.\d{2})?"
              placeholder="00.00"
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Free Shipment threshold:</label>
            <input
              type="text"
              name="freeShipmentThreshold"
              value={settingsData.freeShipmentThreshold}
              onChange={handleChange}
              style={styles.formInput}
              pattern="\d+(\.\d{2})?"
              placeholder="00.00"
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Perishable Price:</label>
            <input
              type="text"
              name="perishablePrice"
              value={settingsData.perishablePrice}
              onChange={handleChange}
              style={styles.formInput}
              pattern="\d+(\.\d{2})?"
              placeholder="00.00"
            />
          </div>
          <button
            type="submit"
            style={{
              ...styles.button,
              backgroundColor: loading ? "silver" : "#478169",
            }}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 50,
  },
};

export default Shipment;
