import React, { useEffect, useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import {} from "react-router-dom";
import { getDocs, addDoc, updateDoc, doc, collection, query, limit, startAfter, orderBy, where, getDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, TextField, Button } from "@mui/material";
import moment from "moment";
import { nanoid } from "nanoid";
import axios from "axios"; // For shortening URLs
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const MarketingProduct = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const storage = getStorage(); // Initialize Firebase Storage

  const [marketingProducts, setMarketingProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [lastVisibleMarketing, setLastVisibleMarketing] = useState(null);
  const [lastVisibleProducts, setLastVisibleProducts] = useState(null);
  const [totalPagesMarketing, setTotalPagesMarketing] = useState(0);
  const [totalPagesProducts, setTotalPagesProducts] = useState(0);
  const [currentPageMarketing, setCurrentPageMarketing] = useState(1);
  const [currentPageProducts, setCurrentPageProducts] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    shortText: "",
    description: "",
    image: null,
    imageUrl: "",
  });
  const [loading, setLoading] = useState(false);

  //

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // Fetch marketing products with pagination
  // Fetch marketing products with pagination
  const fetchMarketingProducts = async (page = 1, reset = false) => {
    let q;

    if (reset) {
      setLastVisibleMarketing(null); // Reset the pagination
    }

    if (lastVisibleMarketing && !reset) {
      q = query(collection(db, `${collectionPath}Marketing/Products/DataBase`), orderBy("product_name"), limit(10), startAfter(lastVisibleMarketing));
    } else {
      q = query(collection(db, `${collectionPath}Marketing/Products/DataBase`), orderBy("product_name"), limit(10));
    }

    const snapshot = await getDocs(q);
    const fetchedMarketingProducts = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setMarketingProducts(fetchedMarketingProducts);

    if (snapshot.docs.length > 0) {
      setLastVisibleMarketing(snapshot.docs[snapshot.docs.length - 1]);
    }

    setTotalPagesMarketing(Math.ceil(100 / 10)); // Adjust this as per the actual number of documents
  };

  // Fetch product list for creating new marketing products with pagination and search
  const fetchProductList = async (page = 1, search = "") => {
    let q;
    if (search) {
      q = query(
        collection(db, `${collectionPath}Products/ProductsList/DataBase`),
        where("product_name", ">=", search),
        where("product_name", "<=", search + "\uf8ff"),
        limit(10)
      );
    } else {
      q = query(collection(db, `${collectionPath}Products/ProductsList/DataBase`), orderBy("product_name"), limit(10), startAfter(lastVisibleProducts || 0));
    }
    const snapshot = await getDocs(q);
    const fetchedProducts = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setProducts(fetchedProducts);
    setLastVisibleProducts(snapshot.docs[snapshot.docs.length - 1]);
    setTotalPagesProducts(Math.ceil(100 / 10)); // Example: 100 total docs
  };

  // Handle clicking on a marketing product for editing and visualization
  const handleSelectMarketingProduct = (product) => {
    setSelectedProduct(product);
    setFormData({
      title: product.title || "",
      shortText: product.shortText || "",
      description: product.description || "",
      imageUrl: product.imageUrl || "",
    });
  };

  // Upload image to Firebase Storage
  const handleImageUpload = async (file) => {
    const storageRef = ref(storage, `Marketing/${file.name}`);
    await uploadBytes(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    return imageUrl;
  };

  // Handle form submission for creating or updating a marketing page
  // Handle form submission for creating or updating a marketing page
  const handleSubmit = async (selectedProduct) => {
    setLoading(true);
    const { title, shortText, description, image } = formData;
    let imageUrl = formData.imageUrl;

    if (image) {
      imageUrl = await handleImageUpload(image); // Ensure the upload completes and get the correct URL
    }

    const code = nanoid(6); // Generates 6-character alphanumeric ID
    const longUrl = `https://sooperstock.com/?m=${code}`;

    // Shorten URL using TinyURL API (or replace with another service)
    const response = await axios.get(`https://tinyurl.com/api-create.php?url=${longUrl}`);
    const shortUrl = response.data;

    if (selectedProduct) {
      const docRef = doc(db, `${collectionPath}Marketing/Products/DataBase`, selectedProduct.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, {
          product_id: selectedProduct.id,
          product_name: selectedProduct.product_name,
          title,
          shortText,
          description,
          imageUrl, // Correctly store the uploaded image URL here
          url: longUrl,
          shortUrl,
          updated_at: moment().format(),
        });
      } else {
        console.error("Document does not exist. Cannot update.");
      }
    } else {
      await addDoc(collection(db, `${collectionPath}Marketing/Products/DataBase`), {
        product_name: title,
        title,
        shortText,
        description,
        imageUrl, // Store new image URL
        url: longUrl,
        shortUrl,
        created_at: moment().format(),
      });
    }

    setFormData({
      title: "",
      shortText: "",
      description: "",
      image: null,
      imageUrl: "",
    });
    await setSelectedProduct(null);
    await fetchMarketingProducts(1, true); // Reset and fetch updated products
    setLoading(false);
  };

  // Handle pagination change for marketing products
  const handleChangePageMarketing = (event, value) => {
    setCurrentPageMarketing(value);
    fetchMarketingProducts(value);
  };

  // Handle pagination change for product list
  const handleChangePageProducts = (event, value) => {
    setCurrentPageProducts(value);
    fetchProductList(value);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    fetchProductList(1, e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchMarketingProducts();
      await fetchProductList();
      setLoading(false);
    };

    fetchData();
  }, []);

  //
  // Delete a marketing product
  const handleDeleteMarketingProduct = async (product) => {
    await deleteDoc(doc(db, `${collectionPath}Marketing/Products/DataBase`, product.id));
    await fetchMarketingProducts(1, true); // Reset pagination after deleting
  };

  return loading ? (
    <div
      style={{
        dispaly: "flex",
        textAlign: "center",
        alignContent: "center",
        height: "100vh",
        flex: 1,
        width: "100%",
      }}
    >
      Loading....
    </div>
  ) : (
    <div>
      <h3>Product Marketing</h3>

      {/* Table for displaying current marketing products */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Campaign URL</TableCell>
              <TableCell>Short URL</TableCell>
              <TableCell>Views</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {marketingProducts.map((product) => (
              <TableRow key={product.id} style={{ cursor: "pointer" }}>
                <TableCell onClick={() => handleSelectMarketingProduct(product)} style={{}}>
                  <u>{product.title}</u>
                </TableCell>
                <TableCell>{product.url}</TableCell>
                <TableCell>{product.shortUrl}</TableCell>
                <TableCell>{product.views}</TableCell>
                <TableCell>
                  <span onClick={() => handleDeleteMarketingProduct(product)}>Delete</span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ padding: "30px 0" }}>
        <Pagination
          count={totalPagesMarketing}
          page={currentPageMarketing}
          onChange={handleChangePageMarketing}
          size={"small"}
          style={{
            display: "flex",
            alignContent: "flex-end",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        />
      </div>

      <div style={{ display: "flex", marginTop: "20px" }}>
        {/* Left block: product list for creating new marketing pages */}
        <div style={{ width: "30%", paddingRight: 20 }}>
          <TextField label="Search Products" variant="standard" fullWidth margin="normal" value={searchTerm} onChange={handleSearchChange} size={"small"} />
          <div>
            {products.map((product) => (
              <div
                key={product.id}
                onClick={() => setSelectedProduct(product)}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ccc",
                  fontSize: "12px",
                }}
              >
                {product.product_name}
              </div>
            ))}
          </div>

          <Pagination count={totalPagesProducts} page={currentPageProducts} onChange={handleChangePageProducts} size={"small"} />
        </div>

        {/* Middle block: Form to create/edit marketing product */}
        <div
          style={{
            width: "35%",
            paddingLeft: "20px",
            borderLeft: ".5px solid silver",
            paddingRight: 20,
          }}
        >
          {selectedProduct && <h4>Create Marketing Landing Page for {selectedProduct.product_name}</h4>}

          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            size={"small"}
            ariant="standard"
          />
          <TextField
            label="Short Text"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.shortText}
            onChange={(e) => setFormData({ ...formData, shortText: e.target.value })}
            size={"small"}
            ariant="standard"
          />
          <input type="file" onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })} />

          <Button variant="contained" color="primary" onClick={() => handleSubmit(selectedProduct)} style={{ marginTop: "20px" }}>
            {selectedProduct ? "Update Marketing Page" : "Create Marketing Page"}
          </Button>
        </div>

        {/* Right block: Visualization */}
        <div
          style={{
            width: "35%",
            paddingLeft: "20px",
            borderLeft: ".5px solid #ccc",
          }}
        >
          <h4>Preview</h4>
          <div
            style={{
              position: "relative",
              // create iphone asspect ratio
              width: "375px",
              height: "600px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                // background image
                position: "absolute",
                height: "100%",
                width: "100%",
                zIndex: 100,
              }}
            >
              <img
                src={"/images/iphone_outline.png"}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div
              style={{
                height: "100%",
                width: "100%",
                zIndex: -100,
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  margin: "10px 70px -30px 50px",
                  height: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {formData.imageUrl && (
                  <img
                    src={formData.imageUrl}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      // image size
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 20,
                  bottom: 20,
                  left: 0,
                  right: 20,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1, padding: "10px 25px" }}></div>
                <div
                  style={{
                    flex: 1,
                    background: "white",
                    padding: "10px 60px",
                  }}
                >
                  <div style={{ fontSize: "30px", paddingBottom: 20 }}>{formData.title}</div>
                  {formData.shortText && <div style={{ fontSize: "16px", paddingBottom: 20 }}>{formData.shortText}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingProduct;
