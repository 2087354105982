import React, { useState, useEffect, useContext } from "react";
import { useFirestore } from "../firebase/FirestoreContext";
import { collection, getDocs, query, setDoc, doc, deleteDoc, where } from "firebase/firestore";
import { TableSortLabel, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const Admins = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [admins, setAdmins] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "email",
    direction: "asc",
  });
  const [newAdminEmail, setNewAdminEmail] = useState("");
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // Track errors
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAdmins();
    setLoading(false);
  }, [db, collectionPath]);

  const fetchAdmins = async () => {
    const adminsCollectionRef = query(collection(db, `_CMS/Admins/DataBase`), where("email", "!=", ""));
    const querySnapshot = await getDocs(adminsCollectionRef);
    const adminsArray = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAdmins(adminsArray);
  };

  const handleSortRequest = (property) => {
    const isAsc = sortConfig.key === property && sortConfig.direction === "asc";
    setSortConfig({ key: property, direction: isAsc ? "desc" : "asc" });
  };

  const sortedAdmins = [...admins].sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue < bValue) {
      return -1 * order;
    }
    if (aValue > bValue) {
      return 1 * order;
    }
    return 0;
  });

  const handleAddAdmin = async () => {
    const normalizedEmail = newAdminEmail.trim().toLowerCase();
    if (normalizedEmail === "") return;

    try {
      // Check if user exists in Users/UsersList/DataBase
      const usersRef = collection(db, "Users/UsersList/DataBase");
      const userQuery = query(usersRef, where("email", "==", normalizedEmail));
      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0]; // Assuming the first matching user is the one we want
        const userID = userDoc.id; // Get userID from the document ID

        // Add this user as an admin in _CMS/Admins/DataBase/{userID}
        const adminRef = doc(db, `_CMS/Admins/DataBase`, userID);
        await setDoc(adminRef, { email: normalizedEmail, id: userID });

        setAdmins([...admins, { id: userID, email: normalizedEmail }]);
        setAddDialogOpen(false);
        setNewAdminEmail("");
        setErrorMessage("");
      } else {
        setErrorMessage("User not found in Users/UsersList/DataBase.");
      }
    } catch (error) {
      setErrorMessage("Error adding admin. Please try again.");
      console.error("Error adding admin:", error);
    }
  };

  const handleRemoveAdmin = async () => {
    if (!selectedAdmin) return;
    const adminRef = doc(db, `_CMS/Admins/DataBase`, selectedAdmin.id);
    await deleteDoc(adminRef);
    setAdmins(admins.filter((admin) => admin.id !== selectedAdmin.id));
    setRemoveDialogOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 1 }}>
          <h2>Admin Management</h2>
        </div>
        <div>
          <div variant="contained" color="primary" onClick={() => setAddDialogOpen(true)} style={{}}>
            <u>Add Admin</u>
          </div>
        </div>
      </div>

      {loading === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Loading...
        </div>
      ) : (
        <table style={styles.table}>
          <thead>
            <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
              <th style={{ ...styles.th, width: "120px", border: 0 }}>
                <TableSortLabel
                  active={sortConfig.key === "email"}
                  direction={sortConfig.key === "email" ? sortConfig.direction : "asc"}
                  onClick={() => handleSortRequest("email")}
                  style={styles.th}
                >
                  UID
                </TableSortLabel>
              </th>
              <th style={styles.th}>
                <TableSortLabel
                  active={sortConfig.key === "email"}
                  direction={sortConfig.key === "email" ? sortConfig.direction : "asc"}
                  onClick={() => handleSortRequest("email")}
                  style={styles.th}
                >
                  Email
                </TableSortLabel>
              </th>
              <th style={styles.th}>Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedAdmins.map((admin) => (
              <tr key={admin.id} style={styles.clickableRow}>
                <td style={styles.td}>{admin.id}</td>
                <td style={styles.td}>{admin.email}</td>
                <td style={styles.td}>
                  <div
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setSelectedAdmin(admin);
                      setRemoveDialogOpen(true);
                    }}
                  >
                    Remove
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Add Admin Dialog */}
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add Admin</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Admin Email"
            type="email"
            fullWidth
            value={newAdminEmail}
            onChange={(e) => setNewAdminEmail(e.target.value)}
          />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddAdmin} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove Admin Dialog */}
      <Dialog open={removeDialogOpen} onClose={() => setRemoveDialogOpen(false)}>
        <DialogTitle>Remove Admin</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove {selectedAdmin?.email}?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoveDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemoveAdmin} color="secondary">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    padding: "8px",
    textAlign: "left",
  },
  td: {
    padding: "8px",
    color: "gray",
  },
  clickableRow: {
    cursor: "pointer",
  },
};

export default Admins;
