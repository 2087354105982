import React, { useContext } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const Products = () => {
  const environment = useContext(EnvContext);
  const location = useLocation();
  const isOutletEmpty = location.pathname === "/main/products";

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  return (
    <div>
      <div style={styles.header}>
        <h2 style={{ lineHeight: "10px", fontWeight: 200 }}>Products</h2>
        <div style={styles.buttonGroup}>
          <Link to={`/main/products/new`}>
            <button style={styles.button}>New Product</button>
          </Link>
          <Link to={`/main/products/uploadcsv`}>
            <button style={styles.button}>Upload CSV</button>
          </Link>
          <Link to={`/main/products/edit`}>
            <button style={styles.button}>Edit Products</button>
          </Link>
        </div>
      </div>

      <div style={{ padding: "10px" }}>{!isOutletEmpty ? <Outlet /> : <div></div>}</div>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "white",
    borderBottom: "1px solid #ccc",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px", // Space between buttons
  },
};

export default Products;
