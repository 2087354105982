import React, { useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { doc, runTransaction, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const NewStore = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore(); // Get Firestore instance from context
  const navigate = useNavigate();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [setLoading] = useState(false);
  const [storeData, setStoreData] = useState({
    seller_name: "",
    seller_description: "",
    contacts: {
      phoneNumber: "",
      email: "",
    },
    location: {
      address: "",
      city: "",
      zip_code: "",
    },
    delivery: {
      description: "",
    },
    payment: {
      venmo: "",
      square: "",
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const storesCollectionRef = collection(db, `${collectionPath}Stores/StoresList/DataBase`);
    const storesIncrementRef = doc(db, `${collectionPath}Stores/StoresList`); // Reference to the counter document

    try {
      const newStoreResult = await runTransaction(db, async (transaction) => {
        const incrementDoc = await transaction.get(storesIncrementRef);
        let newIncrement = 1;
        if (incrementDoc.exists()) {
          newIncrement = (incrementDoc.data().storesIncrement || 0) + 1;
          transaction.update(storesIncrementRef, {
            storesIncrement: newIncrement,
          });
        } else {
          transaction.set(storesIncrementRef, { storesIncrement: 1 });
        }

        const newStore = {
          _collection: "store",
          seller_uid: parseInt(newIncrement),
          seller_name: storeData.seller_name,
          seller_description: storeData.seller_description,
          contacts: {
            phoneNumber: storeData.contacts.phoneNumber,
            email: storeData.contacts.email,
          },
          location: {
            address: storeData.location.address,
            city: storeData.location.city,
            zip_code: storeData.location.zip_code,
          },
          delivery: {
            description: storeData.delivery.description,
          },
          payment: {
            venmo: storeData.payment.venmo,
            square: storeData.payment.square,
          },
          date_of_creation: new Date().toISOString(),
        };

        // Add the new store to the database within the transaction
        const newDocRef = doc(storesCollectionRef);
        transaction.set(newDocRef, newStore);

        return newDocRef.id;
      });

      console.log("Store added successfully with UID:", newStoreResult);
      // Reset form and loading state here
      setStoreData({
        seller_name: "",
        seller_description: "",
        contacts: {
          phoneNumber: "",
          email: "",
        },
        location: {
          address: "",
          city: "",
          zip_code: "",
        },
        delivery: {
          description: "",
        },
        payment: {
          venmo: "",
          square: "",
        },
      });
      navigate(`/main/stores/edit/${newStoreResult}`);
    } catch (error) {
      console.error("Transaction failed: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Insert New Seller</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Seller Name:</label>
            <input
              type="text"
              value={storeData.seller_name}
              onChange={(e) => setStoreData({ ...storeData, seller_name: e.target.value })}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Description:</label>
            <textarea
              value={storeData.seller_description}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  seller_description: e.target.value,
                })
              }
              style={styles.formInput}
            ></textarea>
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Contact Number:</label>
            <input
              type="text"
              value={storeData.contacts.phoneNumber}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  contacts: {
                    ...storeData.contacts,
                    phoneNumber: e.target.value,
                  },
                })
              }
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Email:</label>
            <input
              type="text"
              value={storeData.contacts.email}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  contacts: { ...storeData.contacts, email: e.target.value },
                })
              }
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Location (Address, City, Zip Code):</label>
            <input
              type="text"
              value={storeData.location.address}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  location: { ...storeData.location, address: e.target.value },
                })
              }
              placeholder="Address"
              style={styles.formInput}
            />
            <input
              type="text"
              value={storeData.location.city}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  location: { ...storeData.location, city: e.target.value },
                })
              }
              placeholder="City"
              style={styles.formInput}
            />
            <input
              type="text"
              value={storeData.location.zip_code}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  location: { ...storeData.location, zip_code: e.target.value },
                })
              }
              placeholder="Zip Code"
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Delivery Description:</label>
            <input
              type="text"
              value={storeData.delivery.description}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  delivery: {
                    ...storeData.delivery,
                    description: e.target.value,
                  },
                })
              }
              style={styles.formInput}
            />
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Payment (Venmo, Square):</label>
            <input
              type="text"
              value={storeData.payment.venmo}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  payment: { ...storeData.payment, venmo: e.target.value },
                })
              }
              placeholder="Venmo"
              style={styles.formInput}
            />
            <input
              type="text"
              value={storeData.payment.square}
              onChange={(e) =>
                setStoreData({
                  ...storeData,
                  payment: { ...storeData.payment, square: e.target.value },
                })
              }
              placeholder="Square"
              style={styles.formInput}
            />
          </div>
          <button type="submit" style={styles.button}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
};

export default NewStore;
