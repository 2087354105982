import React, { useState, useEffect, useContext } from "react";
import { useFirestore } from "../firebase/FirestoreContext";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const NewUsersLimit = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [settingsData, setSettingsData] = useState({
    maxNumberOfUsers: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings`, "MaxNumberOfUsers");
      const docSnap = await getDoc(settingsDocRef);

      if (docSnap.exists()) {
        setSettingsData(docSnap.data());
      } else {
        console.error("No such document!");
      }
    };

    fetchSettings();
  }, [db, collectionPath]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validate maxNumberOfUsers format
    const maxUsersPattern = /^\d+$/;
    if (!maxUsersPattern.test(settingsData.maxNumberOfUsers)) {
      toast.error("Maximum number of users must be a valid number");
      setLoading(false);
      return;
    }

    // Convert maxNumberOfUsers to number
    const updatedSettingsData = {
      ...settingsData,
      maxNumberOfUsers: parseInt(settingsData.maxNumberOfUsers, 10),
    };

    const settingsDocRef = doc(db, `${collectionPath}SooperstockSettings`, "MaxNumberOfUsers");

    try {
      const docSnap = await getDoc(settingsDocRef);
      if (docSnap.exists()) {
        await updateDoc(settingsDocRef, updatedSettingsData);
      } else {
        await setDoc(settingsDocRef, updatedSettingsData);
      }
      toast.success("Settings saved successfully");
    } catch (error) {
      toast.error(`Error saving settings: ${error.message}`);
      console.error("Error updating user registration settings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettingsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable />
      <h2 style={{ fontWeight: 200 }}>User Registration Settings</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Max Number of Users:</label>
            <input
              type="text"
              name="maxNumberOfUsers"
              value={settingsData.maxNumberOfUsers}
              onChange={handleChange}
              style={styles.formInput}
              pattern="\d+"
              placeholder="Enter a number"
            />
          </div>
          <button
            type="submit"
            style={{
              ...styles.button,
              backgroundColor: loading ? "silver" : "#478169",
            }}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 50,
  },
};

export default NewUsersLimit;
