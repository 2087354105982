import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { TableSortLabel } from "@mui/material";
import { EnvContext } from "../../context/EnvContext";

const User = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "email",
    direction: "asc",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollectionRef = query(
        collection(db, `Users/UsersList/DataBase`),
        where("email", "!=", "")
      );

      const querySnapshot = await getDocs(usersCollectionRef);
      const usersArray = [];

      querySnapshot.forEach((doc) => {
        const userData = { id: doc.id, ...doc.data() };

        // Only add the user if their ID is not already in the array
        if (!usersArray.some((user) => user.id === userData.id)) {
          usersArray.push(userData);
        }
      });

      setUsers(usersArray);
    };

    fetchUsers();
  }, [db]);

  const handleSortRequest = (property) => {
    const isAsc = sortConfig.key === property && sortConfig.direction === "asc";
    setSortConfig({ key: property, direction: isAsc ? "desc" : "asc" });
  };

  const sortedUsers = [...users].sort((a, b) => {
    const order = sortConfig.direction === "asc" ? 1 : -1;
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue < bValue) {
      return -1 * order;
    }
    if (aValue > bValue) {
      return 1 * order;
    }
    return 0;
  });

  return (
    <div>
      <h2>All Users</h2>
      <table style={styles.table}>
        <thead>
          <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
            <th style={{ ...styles.th, width: "120px", border: 0 }}>
              <TableSortLabel
                active={sortConfig.key === "email"}
                direction={
                  sortConfig.key === "email" ? sortConfig.direction : "asc"
                }
                onClick={() => handleSortRequest("email")}
                style={styles.th}>
                UID
              </TableSortLabel>
            </th>
            <th style={styles.th}>
              <TableSortLabel
                active={sortConfig.key === "email"}
                direction={
                  sortConfig.key === "email" ? sortConfig.direction : "asc"
                }
                onClick={() => handleSortRequest("email")}
                style={styles.th}>
                Email
              </TableSortLabel>
            </th>
            <th style={styles.th}>
              <TableSortLabel
                active={sortConfig.key === "firstName"}
                direction={
                  sortConfig.key === "firstName" ? sortConfig.direction : "asc"
                }
                onClick={() => handleSortRequest("firstName")}
                style={styles.th}>
                First Name
              </TableSortLabel>
            </th>
            <th style={styles.th}>
              <TableSortLabel
                active={sortConfig.key === "lastName"}
                direction={
                  sortConfig.key === "lastName" ? sortConfig.direction : "asc"
                }
                onClick={() => handleSortRequest("lastName")}
                style={styles.th}>
                Last Name
              </TableSortLabel>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((user) => (
            <tr
              key={user.id}
              style={styles.clickableRow}
              onClick={() => navigate(`/main/users/edit/${user.id}`)}>
              <td style={styles.td}>{user.id}</td>
              <td style={styles.td}>{user.email}</td>
              <td style={styles.td}>{user.firstName}</td>
              <td style={styles.td}>{user.lastName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    padding: "8px",
    textAlign: "left",
  },
  td: {
    padding: "8px",
    color: "gray",
  },
  clickableRow: {
    cursor: "pointer",
  },
};

export default User;
