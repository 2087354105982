// NotFound.js
import React from "react";
import { EnvContext } from "../../context/EnvContext";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <div style={styles.containerbox}>
        <h1 style={{ fontWeight: "100", fontSize: 50, lineHeight: "20px" }}>
          404
        </h1>
        <p style={{ fontWeight: "300", paddingBottom: "20px" }}>
          The page you're looking for doesn't exist.
        </p>
        <a href="/" style={{ color: "#0077cc" }}>
          Go back to homepage
        </a>
      </div>
    </div>
  );
};

const styles = {
  container: {
    // width 100% height 100% and content in middle
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    //each div new line
    flexDirection: "column",
    color: "gray",
  },
  containerbox: {
    padding: 20,
    border: "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "20px 50px",
  },
};

export default NotFound;
