import React, { useEffect, useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { getDocs, collection } from "firebase/firestore";
import { saveAs } from "file-saver"; // We will use this to save the CSV file
import moment from "moment"; // We will use this to format the date
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const NewsletterSubscribers = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);

  //
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;
  useEffect(() => {
    const fetchSubscribers = async () => {
      setLoading(true);
      try {
        const subscribersCollectionRef = collection(db, `${collectionPath}Newsletter/Subscribers/DataBase`);
        const querySnapshot = await getDocs(subscribersCollectionRef);
        const subscribersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSubscribers(subscribersData);
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscribers();
  }, [db]);

  // Function to format the CSV data
  const formatCSVData = () => {
    const headers = [
      "from_name",
      "from_email",
      "user_name",
      "password",
      "smtp_host",
      "smtp_port",
      "imap_host",
      "imap_port",
      "max_email_per_day",
      "custom_tracking_url",
      "warmup_enabled",
      "total_warmup_per_day",
      "daily_rampup",
      "reply_rate_percentage",
      "bcc",
      "signature",
      "different_reply_to_address",
      "imap_user_name",
      "imap_password",
    ];

    // Here you can map the data fields accordingly (example fields are placeholders)
    const csvRows = subscribers.map((subscriber) =>
      [
        subscriber.name || "",
        subscriber.email || "",
        subscriber.user_name || "",
        subscriber.password || "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "<p>Thank you,<br>The Sooperstock Team</p>",
        "",
        "",
        "",
      ].join(",")
    );

    return [headers.join(","), ...csvRows].join("\n");
  };

  // Function to handle CSV download
  const handleDownloadCSV = () => {
    const csvData = formatCSVData();
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "newsletter_subscribers.csv");
  };

  return (
    <div>
      <h3>Newsletter Subscribers</h3>
      <button onClick={handleDownloadCSV} style={styles.button}>
        Download CSV
      </button>
      <div style={{ marginTop: "20px" }}>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div style={{ padding: "10px" }}>
            {subscribers.length > 0 ? (
              subscribers.map((subscriber) => (
                <div key={subscriber.id} style={styles.subscriberListItem}>
                  <div style={{ display: "flex", flex: 1 }}>{subscriber.email}</div>
                  <div style={{ opacity: 0.5 }}>{moment(subscriber.subscribedAt.toDate()).format("MM/DD/YY h:m:s a")}</div>
                </div>
              ))
            ) : (
              <div>No subscribers available.</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  button: {
    padding: "10px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
  },
  subscriberListItem: {
    display: "flex",
    padding: "10px 0",
    borderBottom: ".5px solid silver",
    width: "100%",
  },
};

export default NewsletterSubscribers;
