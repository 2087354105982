import React, { useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, query, where, getDocs, doc, writeBatch, updateDoc, arrayUnion } from "firebase/firestore";
import config from "../../../config";
import { EnvContext } from "../../../context/EnvContext";

const UploadCSVProduct = () => {
  const environment = useContext(EnvContext);
  const [file, setFile] = useState(null);
  const [createdCount, setCreatedCount] = useState(0);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [createdData, setCreatedData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const db = useFirestore();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const updateFirestore = async (products) => {
    try {
      setLoading(true);
      const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);
      const batch = writeBatch(db);

      let localCreatedCount = 0;
      let localUpdatedCount = 0;
      let localCreatedData = [];
      let localUpdatedData = [];

      // Initialize brandData
      let brandData = {};

      // Step 1: Check for duplicate UIDs within the CSV file
      const productUIDsFromCSV = products.map((product) => product.product_uid);
      const productMap = new Map();

      // Handle duplicates within the CSV file by ensuring only the last product with a given UID is processed
      products.forEach((product) => {
        productMap.set(product.product_uid, product);
      });

      const uniqueProducts = Array.from(productMap.values());

      // Step 2: Fetch and store brand data
      const brandUIDs = [...new Set(uniqueProducts.map((product) => product.brand_uid))];
      const brandCollectionRef = collection(db, `${collectionPath}Brands/BrandsList/DataBase`);

      const chunkSize = 10;
      const chunks = [];
      for (let i = 0; i < brandUIDs.length; i += chunkSize) {
        chunks.push(brandUIDs.slice(i, i + chunkSize));
      }

      for (const chunk of chunks) {
        const brandQuery = query(brandCollectionRef, where("brand_uid", "in", chunk));
        const brandSnapshot = await getDocs(brandQuery);
        brandSnapshot.forEach((doc) => {
          const brandDataItem = doc.data();
          brandData[brandDataItem.brand_uid] = brandDataItem.brand_name;
        });
      }

      // Step 3: Check for existing UIDs in Firestore
      const productUIDs = [...new Set(uniqueProducts.map((product) => product.product_uid))];
      let existingProducts = {};

      const productChunks = [];
      for (let i = 0; i < productUIDs.length; i += chunkSize) {
        productChunks.push(productUIDs.slice(i, i + chunkSize));
      }

      for (const chunk of productChunks) {
        const productQuery = query(productsCollectionRef, where("product_uid", "in", chunk));
        const productSnapshot = await getDocs(productQuery);
        productSnapshot.forEach((docSnapshot) => {
          existingProducts[docSnapshot.id] = {
            ...docSnapshot.data(),
            id: docSnapshot.id,
          };
        });
      }

      // Step 4: Proceed with the import
      for (const product of uniqueProducts) {
        if (!product.product_name || !product.product_uid) {
          console.log("Skipping product due to missing name or UID:", product);
          continue;
        }

        const newProductStructure = {
          product_uid: product.product_uid,
          brand_uid: product.brand_uid,
          product_name: product.product_name,
          product_description: product.product_description || "",
          effects: product.effects || "",
          product_type: product.product_type || "",
          product_category: product.product_category || "",
          net_weight: parseFloat(product.net_weight) || 0,
          weight_units: product.weight_units || "",
          potency_value: parseFloat(product.potency_value) || 0,
          number_servings: parseInt(product.number_servings, 10) || 0,
          serving_size: product.serving_size || "",
          dosing_guide: product.dosing_guide || "",
          dose_per_serving: product.dose_per_serving || "",
          brand_name: brandData[product.brand_uid] || "", // Use brandData here
          likes: 0,
          rating: {
            rating: 0,
            votes: 0,
          },
          perishable: product.perishable === "true" || product.perishable === true,
        };

        const existingProduct = Object.values(existingProducts).find((p) => p.product_uid === product.product_uid);

        if (existingProduct) {
          if (!existingProduct.id) {
            console.error("existingProduct.id is undefined for product:", product);
            continue;
          }
          const docRef = doc(db, `${collectionPath}Products/ProductsList/DataBase`, existingProduct.id);
          batch.update(docRef, newProductStructure);
          localUpdatedData.push({ ...newProductStructure, updated: true });
          localUpdatedCount++;
        } else {
          const newDocRef = doc(productsCollectionRef);
          batch.set(newDocRef, newProductStructure);
          localCreatedData.push(newProductStructure);
          localCreatedCount++;
        }

        // Update the brand document and products meta document with new categories, effects, and product types
        await updateBrandData(product.brand_uid, product.product_category, product.effects, product.product_type);
        await updateProductsMetaData(product.product_category, product.effects, product.product_type);
      }

      await batch.commit();

      setTimeout(() => {
        setCreatedCount(localCreatedCount);
        setUpdatedCount(localUpdatedCount);
        setCreatedData(localCreatedData);
        setUpdatedData(localUpdatedData);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error updating Firestore:", error);
      alert("Error updating Firestore: " + error.message);
      setLoading(false);
    }
  };

  const updateBrandData = async (brand_uid, category, effects, productType) => {
    try {
      const brandDocRef = doc(db, `${collectionPath}Brands/BrandsList/DataBase`, brand_uid);
      await updateDoc(brandDocRef, {
        brand_category: arrayUnion(category),
        effects: arrayUnion(effects),
        product_type: arrayUnion(productType),
      });
    } catch (error) {
      console.error("Error updating brand data:", error);
    }
  };

  const updateProductsMetaData = async (category, effects, productType) => {
    try {
      const productsMetaDocRef = doc(db, `${collectionPath}Products/ProductsMeta`);
      await updateDoc(productsMetaDocRef, {
        categories: arrayUnion(category),
        effects: arrayUnion(effects),
        product_type: arrayUnion(productType),
      });
    } catch (error) {
      console.error("Error updating products meta data:", error);
    }
  };

  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (!file) {
      alert("Please select a file to upload");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${config.apiConfig.API_ENDPOINT_URL}${environment === "staging" ? "/api/dev" : "/api"}/product/productCSV`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const message = `An error has occurred: ${response.status} - ${await response.text()}`;
        throw new Error(message);
      }
      const result = await response.json();

      await updateFirestore(result);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file: " + error.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Upload CSV File</h1>
      <form onSubmit={handleFormSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <input type="file" onChange={handleFileChange} accept=".csv" style={styles.formInput} />
            <button type="submit" style={styles.button}>
              Upload
            </button>
          </div>
        </div>
      </form>

      <div style={{ ...styles.form, marginTop: "10px" }}>
        <div style={{ padding: "20px" }}>
          {createdCount > 0 || updatedCount > 0 ? (
            <>
              <div style={{ marginBottom: "50px" }}>
                <h4>Results</h4>
                <p style={{ fontSize: "12px", color: "gray" }}>
                  Created: <b style={{ color: "black" }}>{createdCount}</b>
                </p>
                <p style={{ fontSize: "12px", color: "gray" }}>
                  Updated: <b style={{ color: "black" }}>{updatedCount}</b>
                </p>
              </div>
              <div>
                <div style={{ marginBottom: "50px" }}>
                  <h4 style={{ fontWeight: "300" }}>Created Data</h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "10px",
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: ".5px solid gray",
                        paddingBottom: "10px",
                      }}
                    >
                      <div style={{ width: "150px", fontWeight: "bold" }}>Product UID</div>
                      <div style={{ width: "150px", fontWeight: "bold" }}>Brand UID</div>
                      <div style={{ width: "150px", fontWeight: "bold" }}>Product Name</div>
                    </div>
                    {createdData.map((data, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          padding: "5px 0",
                          color: "green",
                          fontWeight: "200",
                        }}
                      >
                        <div style={{ width: "150px" }}>{data.product_uid}</div>
                        <div style={{ width: "150px" }}>{data.brand_uid}</div>
                        <div style={{ width: "150px", fontWeight: "500" }}>{data.product_name}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 style={{ fontWeight: "300" }}>Updated Data</h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "10px",
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: ".5px solid gray",
                        paddingBottom: "10px",
                        fontWeight: "200",
                      }}
                    >
                      <div style={{ width: "150px", fontWeight: "bold" }}>Product UID</div>
                      <div style={{ width: "150px", fontWeight: "bold" }}>Brand UID</div>
                      <div style={{ width: "150px", fontWeight: "bold" }}>Product Name</div>
                    </div>
                    {updatedData.map((data, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          padding: "5px 0px",
                          color: data.updated == false ? "gray" : "green",
                          fontWeight: data.updated == false ? "300" : "600",
                        }}
                      >
                        <div style={{ width: "150px" }}>{data.product_uid}</div>
                        <div style={{ width: "150px" }}>{data.brand_uid}</div>
                        <div style={{ width: "150px", fontWeight: "500" }}>{data.product_name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={{}}>{loading && <div>Loading...</div>}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 50,
  },
};

export default UploadCSVProduct;
