import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useFirestore } from "../firebase/FirestoreContext";
import { EnvContext } from "../../context/EnvContext";

const Login = () => {
  const environment = useContext(EnvContext);
  const [email, setEmail] = useState(""); // Username changed to email for Firebase auth
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const db = useFirestore(); // Access Firestore instance from context
  const auth = getAuth();

  const handleLogin = async () => {
    setError(""); // Clear any previous errors
    try {
      // Sign in the user with Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // After login, check if the user is in the Admin collection in Firestore
      const adminDocRef = doc(db, "_CMS/Admins/DataBase", user.uid);
      const adminDoc = await getDoc(adminDocRef);

      if (adminDoc.exists()) {
        // If user is an admin, navigate to the main dashboard
        navigate("/main");
      } else {
        // If the user is not an admin, show an error
        setError("Access denied. You are not an admin.");
      }
    } catch (err) {
      // Handle login errors (wrong password, no such user, etc.)
      setError("Login failed: " + err.message);
    }
  };

  const handleResetPassword = async () => {
    setError(""); // Clear previous errors
    setMessage(""); // Clear any previous messages
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setMessage("Password reset email sent! Check your inbox.");
    } catch (err) {
      setError("Error sending reset email: " + err.message);
    }
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.containerbox,
          backgroundColor: environment === "staging" ? "#dae121" : "#f9f9f9",
        }}>
        <div
          style={{
            fontFamily: "Space Grotesk",
            fontSize: 28,
            textAlign: "center",
          }}>
          <img
            src="/images/sooperstock_logo.svg"
            alt="Sooperstock"
            style={{ width: "200px" }}
          />
        </div>
        <h3 style={{ fontWeight: 100 }}>
          {isResetPassword ? "Reset Password" : "Login"}
        </h3>

        {/* If the user is trying to reset their password */}
        {isResetPassword ? (
          <>
            <div style={styles.inputTextContainer}>
              <input
                type="email"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                style={styles.inputText}
              />
            </div>
            <button onClick={handleResetPassword} style={styles.inputButton}>
              Send Reset Email
            </button>
            {message && <p style={{ color: "green" }}>{message}</p>}
            {error && (
              <p style={{ color: "red", fontSize: ".7rem" }}>{error}</p>
            )}
            <div style={styles.link} onClick={() => setIsResetPassword(false)}>
              Back to Login
            </div>
          </>
        ) : (
          <>
            <div style={styles.inputTextContainer}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.inputText}
              />
            </div>
            <div style={styles.inputTextContainer}>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.inputText}
              />
            </div>
            <button onClick={handleLogin} style={styles.inputButton}>
              Login
            </button>
            {error && (
              <p style={{ color: "red", fontSize: ".7rem" }}>{error}</p>
            )}
            <div style={styles.link} onClick={() => setIsResetPassword(true)}>
              Forgot your password?
            </div>
            <div
              style={{
                marginTop: "10px",
                color: "gray",
                fontSize: "12px",
                textAlign: "right",
              }}>
              by Sooperstock
            </div>
          </>
        )}
      </div>
      <div style={{ width: "330px", marginTop: 15 }}>
        <div style={{ textAlign: "left", fontSize: "12px", color: "gray" }}>
          Don't have an account?
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  },
  containerbox: {
    padding: 20,
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  inputTextContainer: {
    marginBottom: 10,
    backgroundColor: "rgba(200,200,200,.2)",
  },
  inputText: {
    padding: 15,
    fontSize: 16,
    width: "260px",
    border: ".5px solid #ccc",
    borderRadius: 6,
  },
  inputButton: {
    width: "100%",
    padding: "15px 0",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: 5,
    fontSize: 16,
    textTransform: "uppercase",
  },
  link: {
    cursor: "pointer",
    textAlign: "right",
    fontSize: "12px",
    marginTop: "10px",
  },
};

export default Login;
