import { collection, addDoc } from "firebase/firestore"; // Client SDK

export const createDeliveryMailConfirmation = async (db, orderData) => {
  const { user_id, buyer_address, buyerEmail, sellerEmail, isAnonymous, shippingCost, items, sooperstock_order_id, created_at, seller_id, shipmentAddress } =
    orderData;

  let itemsHtml = "";
  let totalPrice = 0;

  items.forEach((item) => {
    totalPrice += item.listing_price * item.quantity;
    itemsHtml += `
      <tr>
        <td style="padding: 8px; border: 1px solid #ddd;">${item.product_name}</td>
        <td style="padding: 8px; border: 1px solid #ddd;">${item.quantity}</td>
      </tr>
    `;
  });

  const { address_name, address, address_optional } = shipmentAddress || {};

  const mange_message = isAnonymous
    ? "Since you were not logged in when making this purchase, please save this email for future reference."
    : '<p>View or manage <a href="https://www.sooperstock.com/orderhistory">order</a></p>';

  const htmlContent = `
    <html>
    <body>
      <h1>Hello ${address_name || "Customer"},</h1>
      <p>Your order has shipped and is on its way!</p>
      <div>
        <h2>Order #${sooperstock_order_id}</h2>
        <p>Ship to: ${address || "N/A"} ${address_optional || ""}</p>
        ${mange_message}
        <p>Order Total: $${(totalPrice + (shippingCost / 100 || 0)).toFixed(2)}</p>
      </div>
      <h2>Order Summary</h2>
      <table>${itemsHtml}</table>
    </body>
    </html>
  `;

  const textContent = `
    Order ID: ${sooperstock_order_id}
    Buyer Details:
    Name: ${address_name || "N/A"}
    Address: ${address || "N/A"}
  `;

  const message = {
    html: htmlContent,
    text: textContent,
    subject: `Your Sooperstock order has shipped (${sooperstock_order_id})`,
  };

  try {
    const messageCollection = collection(db, "Messages/Mail/DataBase");
    await addDoc(messageCollection, {
      to: [buyerEmail],
      message,
    });
    alert("Order email prepared successfully");
    console.log("Order email prepared successfully");
  } catch (error) {
    console.error("Error sending delivery confirmation email:", error);
  }
};
