import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { doc, collection, getDocs, deleteDoc } from "firebase/firestore";
import config from "../../config";
import { EnvContext } from "../../context/EnvContext";

const Media = () => {
  const environment = useContext(EnvContext);

  const db = useFirestore();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [media, setMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const mediaCollectionRef = collection(db, `${collectionPath}Media/MediaList/DataBase`);

    getDocs(mediaCollectionRef)
      .then((querySnapshot) => {
        const mediaArray = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.url) {
            mediaArray.push({ id: doc.id, ...data });
          }
        });
        setMedia(mediaArray);
      })
      .catch((error) => {
        console.error("Error fetching media:", error);
      });
  }, [db, collectionPath]);

  const handleFileChange = (event) => {
    setFiles((prevFiles) => [...prevFiles, ...Array.from(event.target.files)]);
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    files.forEach((file) => formData.append("file", file));

    try {
      const apiUrl = `${config.apiConfig.API_ENDPOINT_URL}${environment === "staging" ? "/api/dev" : "/api"}/feed/newFeedMedia`;

      const response = await fetch(apiUrl, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        // Extract URLs from the `mediaData` array in the response
        const newImages = result.mediaData.map((item) => ({
          url: item.url,
          type: item.type,
          timestamp: item.timestamp,
        }));

        // Update state with the new images
        setMedia((prevMedia) => [...prevMedia, ...newImages]);
        setFiles([]); // Clear files after successful upload
        fileInputRef.current.value = ""; // Reset file input field
      } else {
        throw new Error("Failed to upload images");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to upload images");
    } finally {
      setLoading(false);
    }
  };

  const deleteImage = async (imageIndex) => {
    setLoading(true);
    const updatedImages = media.filter((_, index) => index !== imageIndex);

    try {
      const mediaDocRef = doc(db, `${collectionPath}Media/MediaList/DataBase`, media[imageIndex].id);
      await deleteDoc(mediaDocRef);
      setMedia(updatedImages);
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Error deleting image");
    } finally {
      setLoading(false);
    }
  };

  const copyUrlToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      alert("URL copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy:", err);
      alert("Failed to copy URL");
    }
  };

  return (
    <div>
      <h2 style={{ fontWeight: 200 }}>Media</h2>
      <form onSubmit={handleImageUpload} style={styles.form}>
        <div style={{ padding: 20 }}>
          <div
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              display: "flex",
              marginBottom: 50,
            }}
          >
            {media.length > 0 &&
              media.map((image, index) => (
                <div
                  key={index}
                  style={{
                    flexDirection: "column",
                    marginBottom: 15,
                    marginRight: 10,
                  }}
                >
                  <img
                    src={image.url}
                    alt="Product"
                    style={{
                      width: 150,
                      height: 150,
                      objectFit: "cover",
                      marginBottom: 10,
                    }}
                    onClick={() => copyUrlToClipboard(image.url)}
                  />
                  <div type="button" onClick={() => deleteImage(index)} style={{ fontSize: 12, color: "red", cursor: "pointer" }}>
                    Delete
                  </div>
                </div>
              ))}
          </div>
          <div style={styles.formElement}>
            <label style={styles.formLabel}>Upload Image:</label>
            <input ref={fileInputRef} type="file" onChange={handleFileChange} style={styles.formInput} accept="image/*" multiple />
          </div>
          <button
            type="submit"
            disabled={loading}
            style={{
              ...styles.button,
              backgroundColor: loading ? "silver" : "#478169",
            }}
          >
            {loading ? "Uploading..." : "Upload Image"}
          </button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
  },
  formSelect: {
    fontSize: 16,
    border: "0.5px solid #ccc",
    borderRadius: 5,
    height: 38,
    backgroundColor: "#fff",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    color: "gray",
  },
  tdName: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    cursor: "pointer",
  },
  clickableRow: {
    cursor: "pointer",
  },
};

export default Media;
