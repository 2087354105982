import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useFirestore } from "../../firebase/FirestoreContext";
import { doc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const EditDosingGuide = () => {
  const environment = useContext(EnvContext);
  const { id } = useParams();
  const navigate = useNavigate();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const db = useFirestore();
  const [dosingGuideData, setDosingGuideData] = useState({
    name: "",
    guides: [{ dose: "", notes: "" }],
  });
  const [allDosingGuides, setAllDosingGuides] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDosingGuide = async () => {
      setLoading(true); // Set loading to true when fetching data
      if (id) {
        // Fetch specific dosing guide if id is provided
        const docRef = doc(db, `${collectionPath}DosingGuides/DosingGuidesList/DataBase`, id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDosingGuideData(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } else {
        // Fetch all dosing guides if no id is provided
        const collectionRef = collection(db, `${collectionPath}DosingGuides/DosingGuidesList/DataBase`);
        const querySnapshot = await getDocs(collectionRef);
        const guidesArray = [];
        querySnapshot.forEach((doc) => {
          guidesArray.push({ id: doc.id, ...doc.data() });
        });
        setAllDosingGuides(guidesArray);
      }
      setLoading(false); // Set loading to false after data is fetched
    };

    fetchDosingGuide();
  }, [db, id, collectionPath]); // Add collectionPath as a dependency

  const handleGuideChange = (index, field, value) => {
    const newGuides = [...dosingGuideData.guides];
    newGuides[index][field] = value;
    setDosingGuideData({ ...dosingGuideData, guides: newGuides });
  };

  const handleAddGuide = () => {
    setDosingGuideData({
      ...dosingGuideData,
      guides: [...dosingGuideData.guides, { dose: "", notes: "" }],
    });
  };

  const handleRemoveGuide = (index) => {
    const newGuides = dosingGuideData.guides.filter((_, i) => i !== index);
    setDosingGuideData({ ...dosingGuideData, guides: newGuides });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, `${collectionPath}DosingGuides/DosingGuidesList/DataBase`, id);
      await updateDoc(docRef, dosingGuideData);
      toast.success("Dosing guide updated successfully");
    } catch (error) {
      console.error("Error updating dosing guide:", error);
      toast.error("Error updating dosing guide");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!id) {
    return (
      <div>
        <ToastContainer />
        <h2>Dosing Guides</h2>
        {allDosingGuides.length === 0 ? (
          <p>No dosing guides available.</p>
        ) : (
          <table style={styles.table}>
            <thead>
              <tr style={{ backgroundColor: "rgba(49,57,66,.1)" }}>
                <th style={{ ...styles.th }}>Guide Name</th>
                <th style={styles.th}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {allDosingGuides.map((guide) => (
                <tr key={guide.id} style={styles.clickableRow} onClick={() => navigate(`/main/dosingGuide/edit/${guide.id}`)}>
                  <td style={styles.td}>{guide.name}</td>
                  <td style={styles.tdName}>
                    <div onClick={() => navigate(`/main/dosingGuide/edit/${guide.id}`)} style={{ cursor: "pointer" }}>
                      Edit
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }

  return (
    <div>
      <ToastContainer />
      <h2>Edit Dosing Guide</h2>
      <button onClick={() => navigate(-1)} style={styles.backButton}>
        Back
      </button>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <div style={{ flexDirection: "column" }}>
              <div>
                <label style={styles.formLabel}>Name:</label>
              </div>
              <div>
                <input
                  type="text"
                  value={dosingGuideData.name}
                  onChange={(e) =>
                    setDosingGuideData({
                      ...dosingGuideData,
                      name: e.target.value,
                    })
                  }
                  style={styles.formInput}
                />
              </div>
            </div>
          </div>
          {dosingGuideData.guides.map((guide, index) => (
            <div key={index} style={styles.formElement}>
              <div style={{ flexDirection: "column", flex: 1, paddingRight: 20 }}>
                <div>
                  <label style={styles.formLabel}>Dose:</label>
                </div>
                <div style={{ width: "90%" }}>
                  <input type="text" value={guide.dose} onChange={(e) => handleGuideChange(index, "dose", e.target.value)} style={styles.formInput} />
                </div>
              </div>
              <div style={{ flexDirection: "column", flex: 1 }}>
                <div>
                  <label style={styles.formLabel}>Notes:</label>
                </div>
                <div style={{ width: "90%" }}>
                  <input type="text" value={guide.notes} onChange={(e) => handleGuideChange(index, "notes", e.target.value)} style={styles.formInput} />
                </div>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  flex: 0.5,
                  alignContent: "center",
                }}
              >
                {dosingGuideData.guides.length > 1 && (
                  <div type="button" onClick={() => handleRemoveGuide(index)} style={{ cursor: "pointer" }}>
                    Remove
                  </div>
                )}
              </div>
            </div>
          ))}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div type="button" onClick={handleAddGuide} style={{ cursor: "pointer" }}>
              Add more guides +
            </div>
          </div>
          <div style={{ marginTop: 50 }}>
            <button type="submit" style={styles.button}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "row",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
    width: "100%",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 50,
  },
  backButton: {
    padding: "10px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    marginBottom: 20,
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
  },
  th: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    textAlign: "left",
  },
  td: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    color: "gray",
  },
  tdName: {
    border: "1px solid #d1d0d0",
    padding: "8px",
    cursor: "pointer",
  },
  clickableRow: {
    cursor: "pointer",
  },
};

export default EditDosingGuide;
