import React, { useEffect, useState, useContext } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { collection, getDocs } from "firebase/firestore";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const DosignGuide = () => {
  const environment = useContext(EnvContext);
  const location = useLocation();
  const isOutletEmpty = location.pathname === "/main/products";

  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // get number of products from firestore collection Products/ProductsList/DataBase
  const db = useFirestore();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsCollectionRef = collection(db, `${collectionPath}Products/ProductsList/DataBase`);

    getDocs(productsCollectionRef)
      .then((querySnapshot) => {
        const productsArray = [];
        querySnapshot.forEach((doc) => {
          productsArray.push({ id: doc.id, ...doc.data() });
        });
        setProducts(productsArray);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, [db]);

  return (
    <div>
      <div style={styles.header}>
        <h2 style={{ lineHeight: "10px", fontWeight: 200 }}>Dosing Guide</h2>
        <div style={styles.buttonGroup}>
          <Link to={`/main/dosingGuide/new`}>
            <button style={styles.button}>New DG</button>
          </Link>
          <Link to={`/main/dosingGuide/edit`}>
            <button style={styles.button}>Edit DG</button>
          </Link>
        </div>
      </div>

      <div style={{ padding: "10px" }}>{!isOutletEmpty ? <Outlet /> : <div></div>}</div>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #ccc",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  buttonGroup: {
    display: "flex",
    gap: "10px", // Space between buttons
  },
};

export default DosignGuide;
