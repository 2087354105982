import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useFirestore } from "../firebase/FirestoreContext"; // Firestore instance context

// Create UserContext
const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Firebase Auth user
  const [adminData, setAdminData] = useState(null); // Admin data from Firestore
  const [loading, setLoading] = useState(true); // Loading state
  const db = useFirestore(); // Firestore instance
  const auth = getAuth(); // Firebase Auth instance

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true); // Start loading when auth state changes

      if (currentUser) {
        setUser(currentUser); // Set the current user
        const userId = currentUser.uid;

        try {
          // Fetch admin data from Firestore
          const userRef = doc(db, "_CMS/Admins/DataBase", userId);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data(); // Get admin data (e.g., userName)
            setAdminData(userData); // Store admin data
          } else {
            setAdminData(null); // No admin data found
          }
        } catch (error) {
          console.error("Error fetching admin data: ", error);
          setAdminData(null); // Ensure admin data is null in case of error
        }
      } else {
        setUser(null); // No user is logged in
        setAdminData(null); // No admin data
      }

      setLoading(false); // Loading finished
    });

    // Cleanup subscription on unmount
    return () => unsubscribeAuth();
  }, [auth, db]);

  return <UserContext.Provider value={{ user, adminData, loading }}>{children}</UserContext.Provider>;
};
