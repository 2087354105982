import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { getDocs, collection, query, where, doc, getDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import moment from "moment";
import { CSVLink } from "react-csv";
import { EnvContext } from "../../context/EnvContext";
import { createDeliveryMailConfirmation } from "../functions/sendingMail/sendingDeliveryMail"; // Import the function
import config from "../../config";

const Orders = () => {
  const environment = useContext(EnvContext);
  const { seller_id, id } = useParams();
  const db = useFirestore();
  const navigate = useNavigate();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [products, setProducts] = useState([]);
  const [store, setStore] = useState(null);
  const [stores, setStores] = useState([]);
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [sortField, setSortField] = useState("status"); // Default sort by status
  const [sortDirection, setSortDirection] = useState("asc"); // Ascending by default
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!seller_id) return;
      setLoading(true);
      setProducts([]);
      try {
        const ordersCollectionRef = collection(db, `${collectionPath}Orders/OrdersList/DataBase`);
        const q = query(ordersCollectionRef, where("seller_id", "==", seller_id));
        const ordersDoc = await getDocs(q);

        const orders = ordersDoc.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          status: doc.data().status || "Not started", // Set default status to "Not started"
          items: doc.data().items.map((item) => ({
            ...item,
            notes: item.notes || "", // Ensure notes are initialized for each item
          })),
        }));

        setOrders(orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchStores = async () => {
      setLoading(true);
      try {
        const storesCollectionRef = collection(db, `${collectionPath}Stores/StoresList/DataBase`);
        const querySnapshot = await getDocs(storesCollectionRef);
        const stores = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched Stores:", stores);

        setStores(stores);
      } catch (error) {
        console.error("Error fetching stores:", error);
      } finally {
        setLoading(false);
      }
    };

    if (seller_id) {
      fetchOrders();
    } else {
      fetchStores();
    }
  }, [seller_id, db, collectionPath]);

  const handleNotesChange = (productId, value) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) => ({
        ...order,
        items: order.items.map((item) => (item.id === productId ? { ...item, notes: value } : item)),
      }))
    );
  };

  const saveChanges = async (e) => {
    e.preventDefault();
    setLoading(true);
    const listingRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);

    for (const product of products) {
      const docId = `${id}_${product.id}`;
      const listingDocRef = doc(listingRef, docId);
      const listingDoc = await getDoc(listingDocRef);

      if (!product.listing_price) {
        // then delete from the Listings/ListingsList/DataBase collection
        if (listingDoc.exists()) {
          await deleteDoc(listingDocRef);
        }
        continue;
      }

      // things from form that are empty can't be put in the database
      Object.keys(product).forEach((key) => {
        if (product[key] === null || product[key] === undefined) {
          delete product[key];
        }
      });

      // if the product is not available set seller_product_rating to 0
      if (product.listing_product_notAvailable) {
        product.seller_product_rating = 0;
      }

      const dataToSave = {
        ...product,
        _collection: "listing",
        seller_id: id,
        seller_name: store?.seller_name,
        seller_uid: store?.seller_uid,
        product_id: product.id,
        seller_product_id: docId,
      };

      // Remove product.id from dataToSave
      delete dataToSave.id;

      if (listingDoc.exists()) {
        await updateDoc(listingDocRef, dataToSave);
      } else {
        await setDoc(listingDocRef, dataToSave);
      }
    }

    setLoading(false);
  };

  const toggleDetails = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };
  //
  const changeItemStatus = async (productId, status) => {
    setLoading(true);
    const ordersCollectionRef = collection(db, `${collectionPath}Orders/OrdersList/DataBase`);
    const orderDocRef = doc(ordersCollectionRef, expandedOrderId);
    const orderDoc = await getDoc(orderDocRef);

    if (orderDoc.exists()) {
      const orderData = orderDoc.data();
      const updatedItems = orderData.items.map((item) => {
        if (item.id === productId) {
          item.sending_status = status;
        }
        return item;
      });

      // Check if all items now have "Pick/pack" status
      const allItemsPicked = updatedItems.every((item) => item.sending_status === "pick_pack");

      // Update order status to "Preparing" if all items are "Pick/pack"
      const newStatus = allItemsPicked ? "Preparing" : orderData.status;

      await updateDoc(orderDocRef, { items: updatedItems, status: newStatus });
      setOrders((prevOrders) => prevOrders.map((order) => (order.id === expandedOrderId ? { ...order, items: updatedItems, status: newStatus } : order)));
    } else {
      console.error("Order not found with id:", productId);
    }

    setLoading(false);
  };

  const changeOrderStatus = async (orderId, status) => {
    setLoading(true);
    const ordersCollectionRef = collection(db, `${collectionPath}Orders/OrdersList/DataBase`);

    const orderDocRef = doc(ordersCollectionRef, orderId);
    const orderDoc = await getDoc(orderDocRef);

    if (orderDoc.exists()) {
      await updateDoc(orderDocRef, { status });
      setOrders((prevOrders) => prevOrders.map((order) => (order.id === orderId ? { ...order, status } : order)));
    } else {
      console.error("Order not found with id:", orderId);
    }

    setLoading(false);
  };

  const filteredOrders = orders.filter((order) => {
    // Filter based on search term
    const orderId = order.sooperstock_order_id?.toLowerCase() || "";
    const address = order?.shipmentAddress?.address?.toLowerCase() || "";
    const matchesSearch = orderId.includes(searchTerm.toLowerCase()) || address.includes(searchTerm.toLowerCase());

    // Filter out "Complete" orders unless they are in the most recent 10
    return matchesSearch && order.status !== "Complete";
  });

  // Get the 10 most recent completed orders that match the search term
  const recentCompleteOrders = orders
    .filter(
      (order) =>
        order.status === "Complete" &&
        (order.sooperstock_order_id?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order?.shipmentAddress?.address?.toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 10);

  // Combine the filtered non-complete orders with the 10 most recent complete orders
  const finalFilteredOrders = [...filteredOrders, ...recentCompleteOrders];

  const sortedOrders = filteredOrders.sort((a, b) => {
    let compareA = a[sortField] || ""; // Default to empty string if field is missing
    let compareB = b[sortField] || ""; // Default to empty string if field is missing

    if (sortField === "createdAt") {
      // Sort by 'createdAt' in reverse chronological order by default
      compareA = new Date(a?.paymentDetails?.payment?.createdAt) || new Date();
      compareB = new Date(b?.paymentDetails?.payment?.createdAt) || new Date();
      return compareB - compareA; // Descending order
    }

    if (sortDirection === "asc") {
      return compareA > compareB ? 1 : -1;
    } else {
      return compareA < compareB ? 1 : -1;
    }
  });

  //
  const toggleOrderSelection = (orderId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(orderId) ? prevSelectedOrders.filter((id) => id !== orderId) : [...prevSelectedOrders, orderId]
    );
  };
  //
  const saveProductNotes = async (productId) => {
    setLoading(true);
    try {
      const listingRef = collection(db, `${collectionPath}Listings/ListingsList/DataBase`);
      const listingDocRef = doc(listingRef, `${id}_${productId}`);
      const listingDoc = await getDoc(listingDocRef);

      const product = products.find((p) => p.id === productId); // Find the product to save notes for
      if (listingDoc.exists()) {
        await updateDoc(listingDocRef, { notes: product.notes }); // Update the notes field in Firestore
        console.log("Notes updated successfully for product:", productId);
      }
    } catch (error) {
      console.error("Error saving notes:", error);
    } finally {
      setLoading(false);
    }
  };

  //
  const generateCsvData = () => {
    const csvData = [
      ["Order ID", "Name", "Address"], // CSV headers
      ...selectedOrders.map((orderId) => {
        const order = orders.find((o) => o.id === orderId);
        return [
          order.sooperstock_order_id,
          order?.shipmentAddress?.address_name,
          order?.shipmentAddress?.address,
          order?.shipmentAddress?.city,
          order?.shipmentAddress?.state,
          order?.shipmentAddress?.zip_code,
          order?.shipmentAddress?.country || "USA", // Default to USA if missing
        ];
      }),
    ];

    return csvData;
  };

  //
  const sendDeliveryConfirmation = async (order) => {
    const orderData = {
      user_id: order.user_id,
      buyer_address: order.buyer_address,
      buyerEmail: order.paymentDetails?.customer_details?.email || "N/A",
      sellerEmail: order.sellerEmail,
      isAnonymous: order.isAnonymous || false,
      shippingCost: order.shippingCost || 0,
      items: order.items || [],
      sooperstock_order_id: order.sooperstock_order_id,
      created_at: order?.created_at || new Date().toISOString(),
      seller_id: order.seller_id,
      shipmentAddress: order.shipmentAddress || {},
    };

    try {
      // Send the delivery confirmation email
      await createDeliveryMailConfirmation(db, orderData);

      // Update Firestore with the email log
      const ordersCollectionRef = collection(db, `${collectionPath}Orders/OrdersList/DataBase`);
      const orderDocRef = doc(ordersCollectionRef, order.id);

      await updateDoc(orderDocRef, {
        sendedMail: [
          ...(order.sendedMail || []), // Keep previous logs if they exist
          { mail_type: "package_shipped", date: new Date().toISOString() },
        ],
      });

      console.log("Delivery confirmation sent and log updated successfully.");
    } catch (error) {
      console.error("Error sending delivery confirmation:", error);
    }
  };

  return (
    <div>
      <h2 style={{ fontWeight: "200" }}>
        Orders <span style={{ fontWeight: "300" }}>{store?.seller_name}</span>
      </h2>
      {!seller_id ? (
        <form onSubmit={saveChanges} style={styles.form}>
          <div style={{ padding: "20px" }}>
            <div style={{ ...styles.formElement, width: "100%" }}>
              {stores.map((store) => (
                <div
                  key={store.id}
                  style={{
                    display: "flex",
                    padding: "10px 0",
                    borderBottom: ".5px solid silver",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/main/orders/${store.id}`)}
                >
                  <div style={{ width: "150px", color: "gray" }}>{store.seller_uid}</div>
                  <div>{store.seller_name}</div>
                </div>
              ))}
            </div>
          </div>
        </form>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <div onClick={() => navigate(`/main/orders`)} style={{ padding: "20px 0", cursor: "pointer" }}>
              {"<"} Back to Stores
            </div>
            <div style={{ color: "black", padding: "20px 0", cursor: "pointer" }}>
              <CSVLink data={generateCsvData()} filename="usps_shipping_labels.csv" className="btn btn-primary" target="_blank">
                Download Selected Orders as CSV
              </CSVLink>
            </div>
          </div>

          <form onSubmit={saveChanges} style={styles.form}>
            <div style={{ padding: "20px" }}>
              {finalFilteredOrders.length === 0 ? (
                <div style={{ ...styles.td, textAlign: "center" }}>No orders found</div>
              ) : (
                <div>
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      placeholder="Search by Order ID or Address"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{
                        padding: "10px",
                        width: "50%",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => setSearchTerm("")}
                      style={{
                        padding: "0px 20px",
                        marginLeft: 10,
                        backgroundColor: "gray",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "18px",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      &times;
                    </button>
                  </div>
                  <table style={{ ...styles.table }}>
                    <thead>
                      <tr style={{ backgroundColor: "rgba(49,57,66,.02)" }}>
                        <th style={styles.th}></th>
                        <th style={styles.th}>Order ID</th>
                        <th style={styles.th}>Status</th>
                        <th style={styles.th}>Payment Type</th>
                        <th style={styles.th}>Date</th>
                        <th style={styles.th}>Open Details</th>
                        <th
                          style={{
                            ...styles.ths,
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                            border: 0,
                            height: "20px",
                            fontSize: 12,
                            alignContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <div onClick={() => setSortDirection(sortDirection === "asc" ? "desc" : "asc")} style={{}}>
                            Order Status {sortDirection === "asc" ? "▲" : "▼"}
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {sortedOrders.map((order) => {
                        // switch status color by order status
                        let statusColor;
                        switch (order.status) {
                          case "in_progress":
                            statusColor = "orange";
                            break;
                          case "reviewing":
                            statusColor = "orange";
                            break;
                          case "shipped":
                            statusColor = "#478169";
                            break;
                          case "complete":
                            statusColor = "#4bdc62";
                            break;
                          case "canceled":
                            statusColor = "silver";
                            break;
                          case "escalate":
                            statusColor = "red";
                            break;
                          case "returned":
                            statusColor = "purple";
                            break;
                          default:
                            statusColor = "#c7bec0";
                        }
                        const createdAt = new Date(order?.paymentDetails?.payment?.createdAt);
                        return (
                          <React.Fragment key={order.id}>
                            <tr
                              style={{
                                backgroundColor: expandedOrderId === order.id ? "rgba(49,57,66,.1)" : "rgba(49,57,66,.03)",
                                border: "1px solid white",
                              }}
                            >
                              <td>
                                <input type="checkbox" checked={selectedOrders.includes(order.id)} onChange={() => toggleOrderSelection(order.id)} />
                              </td>
                              <td
                                style={{
                                  ...styles.td,
                                  fontWeight: "700",
                                  borderLeft: "5px solid",
                                  borderColor: statusColor,
                                }}
                              >
                                {order.sooperstock_order_id}
                              </td>
                              <td style={{ ...styles.td }}>{order.status || "Not started"}</td>
                              <td style={{ ...styles.td }}>{order.paymentType}</td>
                              <td
                                style={{
                                  ...styles.td,
                                }}
                              >
                                {moment(order?.created_at ? order.created_at.toDate() : new Date()).format("MM/DD/YY, h:mm a")}
                              </td>
                              <td style={{ ...styles.td, cursor: "pointer" }} onClick={() => toggleDetails(order.id)}>
                                {expandedOrderId === order.id ? "Hide Details" : "Show Details"}
                              </td>
                              <td style={{ ...styles.td }}>
                                <select value={order.status} onChange={(e) => changeOrderStatus(order.id, e.target.value)}>
                                  <option value="in_progress">Preparing</option>
                                  <option value="shipped">Shipped</option>
                                  <option value="complete">Complete</option>
                                  <option value="canceled">Canceled</option>
                                  <option value="escalate">Escalate</option>
                                  <option value="returned">Returned</option>
                                  <option value="reviewing">Reviewing</option>
                                </select>
                              </td>
                            </tr>
                            {expandedOrderId === order.id && (
                              <>
                                <tr
                                  style={{
                                    padding: 10,
                                    borderBottom: 0,
                                  }}
                                >
                                  <td
                                    colSpan={7}
                                    style={{
                                      ...styles.td,
                                      padding: 10,
                                      backgroundColor: "white",
                                      margin: 10,
                                    }}
                                  >
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <div
                                        style={{
                                          border: ".5px solid silver",
                                          padding: 20,
                                          borderRadius: 10,
                                          marginTop: 10,
                                          width: 500,
                                          marginRight: 20,
                                        }}
                                      >
                                        <b>Order By:</b>
                                        <table style={{ width: "100%" }}>
                                          <tbody>
                                            <tr>
                                              <td style={{ opacity: 0.5 }}>User UID:</td>
                                              <td>{order?.user_id}</td>
                                            </tr>
                                            <tr>
                                              <td style={{ opacity: 0.5 }}>Email:</td>
                                              <td>{order?.paymentDetails?.customer_details?.email || "N/A"}</td>
                                            </tr>
                                            <tr>
                                              <td style={{ opacity: 0.5 }}>Name:</td>
                                              <td>{order?.shipmentAddress?.address_name}</td>
                                            </tr>
                                            <tr>
                                              <td style={{ opacity: 0.5 }}>Address:</td>
                                              <td>{order?.shipmentAddress?.address}</td>
                                            </tr>
                                            {order?.shipmentAddress?.address_optional && (
                                              <tr>
                                                <td style={{ opacity: 0.5 }}>Addres (optional):</td>
                                                <td>{order?.shipmentAddress?.address_optional}</td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      <div style={{ border: ".5px solid silver", padding: 20, borderRadius: 10, marginTop: 10, flex: 1 }}>
                                        <div style={{ flex: 1, padding: "10px 0" }}>
                                          <b>Sending Email:</b>
                                        </div>
                                        <div style={{ height: "100%", flex: 1, padding: "10px 0" }}>
                                          <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ ...styles.buttonMail, width: 150 }} onClick={() => sendDeliveryConfirmation(order)}>
                                              Package shipped
                                            </div>
                                            <div
                                              style={{ display: "flex", textAlign: "right", flex: 1, justifyContent: "flex-end", padding: "0px 10px" }}
                                            ></div>
                                          </div>
                                          {/* Display Email Logs */}
                                          <div style={{ marginTop: "10px" }}>
                                            <h4>Email Logs</h4>
                                            {order.sendedMail && order.sendedMail.length > 0 ? (
                                              <ul style={{ fontSize: 12 }}>
                                                {order.sendedMail.map((log, index) => (
                                                  <li key={index}>
                                                    <strong>{log.mail_type}</strong> - {moment(log.date).format("MM/DD/YYYY, h:mm A")}
                                                  </li>
                                                ))}
                                              </ul>
                                            ) : (
                                              <p>No emails sent yet for this order.</p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    backgroundColor: "white",
                                    borderBottom: 0,
                                    borderTop: 0,
                                  }}
                                >
                                  <td
                                    colSpan={7}
                                    style={{
                                      ...styles.td,
                                      padding: 10,
                                    }}
                                  >
                                    <b>Products:</b>
                                    <div
                                      style={{
                                        border: ".5px solid silver",
                                        padding: 20,
                                        borderRadius: 10,
                                        marginTop: 10,
                                      }}
                                    >
                                      <table style={styles.itemsTable}>
                                        <thead>
                                          <tr>
                                            <th style={styles.itemsTh}>No.</th>
                                            <th style={styles.itemsTh}>Product Name</th>
                                            <th style={styles.itemsTh}>Quantity</th>
                                            <th style={styles.itemsTh}>Price</th>
                                            <th style={styles.itemsTh}>Total</th>
                                            <th style={styles.itemsTh}>Sending Status</th>
                                            <th style={styles.itemsTh}>Notes</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {order.items.map((product, index) => {
                                            const sendingStatusOptions = [
                                              {
                                                value: "not_sent",
                                                label: "Not sent",
                                              },
                                              {
                                                value: "pick_pack",
                                                label: "Pick/pack",
                                              },
                                              {
                                                value: "labeling",
                                                label: "Labeling",
                                              },
                                              { value: "sent", label: "Sent" },
                                              {
                                                value: "delivered",
                                                label: "Delivered",
                                              },
                                              {
                                                value: "returned",
                                                label: "Returned",
                                              },
                                              {
                                                value: "canceled",
                                                label: "Canceled",
                                              },
                                              {
                                                value: "refunded",
                                                label: "Refunded",
                                              },
                                              {
                                                value: "backorder",
                                                label: "Backorder",
                                              },
                                            ];

                                            return (
                                              <tr key={index}>
                                                <td style={styles.itemsTd}>
                                                  <b>{index + 1}</b>
                                                </td>
                                                <td style={styles.itemsTd}>{product.product_name}</td>
                                                <td style={styles.itemsTd}>{product.quantity}</td>
                                                <td style={styles.itemsTd}>${product?.listing_price && Number(product.listing_price).toFixed(2)}</td>
                                                <td style={styles.itemsTd}>
                                                  ${product?.listing_price && Number(product.quantity * product.listing_price).toFixed(2)}
                                                </td>
                                                <td style={styles.itemsTd}>
                                                  <select value={product.sending_status} onChange={(e) => changeItemStatus(product.id, e.target.value)}>
                                                    {sendingStatusOptions.map((option) => (
                                                      <option key={option.value} value={option.value}>
                                                        {option.label}
                                                      </option>
                                                    ))}
                                                  </select>
                                                </td>
                                                {/* Notes input field */}
                                                <td
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",

                                                    marginTop: 5,
                                                  }}
                                                >
                                                  <input
                                                    type="text"
                                                    value={product.notes || ""} // Bind the input to the notes value in the product
                                                    onChange={(e) => handleNotesChange(product.id, e.target.value)}
                                                    placeholder="Enter notes here"
                                                    style={styles.notesInput}
                                                  />

                                                  <button onClick={() => saveProductNotes(product.id)} style={styles.saveNotesButton}>
                                                    Save Notes
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    backgroundColor: "white",
                                    border: "1px solid #808080",
                                    borderTop: 0,
                                  }}
                                >
                                  <td
                                    colSpan={6}
                                    style={{
                                      ...styles.td,
                                      padding: 10,
                                      textAlign: "right",
                                      display: "none",
                                    }}
                                  >
                                    <button type="submit" style={styles.button}>
                                      Save Changes
                                    </button>
                                  </td>
                                </tr>
                              </>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </form>
        </>
      )}
    </div>
  );
};

const styles = {
  tabButton: {
    padding: "10px",
    backgroundColor: "white",
    cursor: "pointer",
    marginRight: 5,
    borderRadius: "5px 5px 0 0",
    fontSize: 12,
  },
  //
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "column",
    gap: 0,
    marginBottom: 10,
    width: 100,
    marginRight: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
    textAlign: "left",
    backgroundColor: "rgba(255,255,255,.8)",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    height: "50px",
  },
  //
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "left",
    borderBottom: ".5px solid silver",
    marginBottom: 20,
    fontSize: 12,
    paddingLeft: 10,
  },
  td: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    textAlign: "left",
  },
  itemsTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  itemsTh: {
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "left",
    borderBottom: ".5px solid silver",
    marginBottom: 20,
    fontSize: 12,
  },
  itemsTd: {
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "left",
  },
  //
  notesInput: {
    width: "100%",
    padding: "5px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  saveNotesButton: {
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100px",
    marginLeft: 10,
  },
  //
  buttonMail: {
    backgroundColor: "#fafafa",
    color: "black",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
    borderRadius: "4px",
    width: "100px",
    marginRight: 10,
    border: "1px solid #ccc",
    textAlign: "center",
  },
};

export default Orders;
