import React, { useState, useContext } from "react";
import { useFirestore } from "../../firebase/FirestoreContext";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { EnvContext } from "../../../context/EnvContext";
import config from "../../../config";

const NewDosingGuide = () => {
  const environment = useContext(EnvContext);
  const db = useFirestore();
  const navigate = useNavigate();

  // Determine the base path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const [dosingGuideData, setDosingGuideData] = useState({
    name: "",
    guides: [{ dose: "", notes: "" }],
  });

  const handleGuideChange = (index, field, value) => {
    const newGuides = [...dosingGuideData.guides];
    newGuides[index][field] = value;
    setDosingGuideData({ ...dosingGuideData, guides: newGuides });
  };

  const handleAddGuide = () => {
    setDosingGuideData({
      ...dosingGuideData,
      guides: [...dosingGuideData.guides, { dose: "", notes: "" }],
    });
  };

  const handleRemoveGuide = (index) => {
    const newGuides = dosingGuideData.guides.filter((_, i) => i !== index);
    setDosingGuideData({ ...dosingGuideData, guides: newGuides });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, `${collectionPath}DosingGuides/DosingGuidesList/DataBase`), dosingGuideData);
      toast.success("Dosing guide added successfully");

      // Redirect to the edit page for the newly created dosing guide
      navigate(`/main/dosingGuide/edit/${docRef.id}`);
    } catch (error) {
      console.error("Error adding dosing guide:", error);
      toast.error("Error adding dosing guide");
    }
  };

  return (
    <div>
      <ToastContainer />
      <h2>New Dosing Guide</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={{ padding: "20px" }}>
          <div style={styles.formElement}>
            <div style={{ flexDirection: "column" }}>
              <div>
                <label style={styles.formLabel}>Name:</label>
              </div>
              <div>
                <input
                  type="text"
                  value={dosingGuideData.name}
                  onChange={(e) =>
                    setDosingGuideData({
                      ...dosingGuideData,
                      name: e.target.value,
                    })
                  }
                  style={styles.formInput}
                />
              </div>
            </div>
          </div>
          {dosingGuideData.guides.map((guide, index) => (
            <div key={index} style={styles.formElement}>
              <div style={{ flexDirection: "column", flex: 1, paddingRight: 20 }}>
                <div>
                  <label style={styles.formLabel}>Dose:</label>
                </div>
                <div style={{ width: "90%" }}>
                  <input type="text" value={guide.dose} onChange={(e) => handleGuideChange(index, "dose", e.target.value)} style={styles.formInput} />
                </div>
              </div>
              <div style={{ flexDirection: "column", flex: 1 }}>
                <div>
                  <label style={styles.formLabel}>Notes:</label>
                </div>
                <div style={{ width: "90%" }}>
                  <input type="text" value={guide.notes} onChange={(e) => handleGuideChange(index, "notes", e.target.value)} style={styles.formInput} />
                </div>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  flex: 0.5,
                  alignContent: "center",
                }}
              >
                {dosingGuideData.guides.length > 1 && (
                  <div type="button" onClick={() => handleRemoveGuide(index)} style={{ cursor: "pointer" }}>
                    Remove
                  </div>
                )}
              </div>
            </div>
          ))}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div type="button" onClick={handleAddGuide} style={{ cursor: "pointer" }}>
              Add more guides +
            </div>
          </div>
          <div style={{ marginTop: 50 }}>
            <button type="submit" style={styles.button}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: 300,
    border: "1px solid #ccc",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    width: "100%",
  },
  formElement: {
    display: "flex",
    flexDirection: "row",
    gap: 0,
    marginBottom: 10,
  },
  formLabel: {
    marginBottom: 5,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#4a4141",
  },
  formInput: {
    padding: "10px 15px",
    marginBottom: 10,
    fontSize: 16,
    border: ".5px solid #ccc",
    borderRadius: 5,
    width: "100%",
  },
  button: {
    padding: "0px",
    width: "120px",
    backgroundColor: "#478169",
    color: "white",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 14,
    height: 50,
  },
};

export default NewDosingGuide;
